import React, {Component, Fragment} from "react";
import {observer} from "mobx-react"
import AddIcon from "@material-ui/icons/Add";
import DeleteIcon from "@material-ui/icons/Delete";
import SendIcon from "@material-ui/icons/Send";
import ConfirmationDialog from "../../../components/common/ConfirmationDialog";
import {
  FullWideStat,
  LeftAlignedListItem,
  NarrowStat,
  RightAlignedActions,
  SpacyFab,
  WideStat
} from "./styled/StyledCharactersList";

import {
  Checkbox,
  Fab,
  FormControlLabel,
  IconButton,
  List,
  ListItemSecondaryAction,
  ListItemText,
  Switch,
  Typography,
} from '@material-ui/core';
import {DD5eStore} from "../../../screens";


interface NonPlayerCharactersListProps {
  screen: DD5eStore  // TODO: Replace with generic interface
}


interface NonPlayerCharactersListState {
    selectedCharacters?: [],
    allSelected: boolean,
    npcsAutoInit: boolean,
    confirmDialogOpened: boolean,
    confirmDialogContent?: string,
    confirmDialogCallback?: (boolean) => void
}



@observer
export class NonPlayerCharactersList extends Component<NonPlayerCharactersListProps, NonPlayerCharactersListState> {

  state: Readonly<NonPlayerCharactersListState> = {
    selectedCharacters: [],
    allSelected: false,
    npcsAutoInit: true,
    confirmDialogOpened: false,
    confirmDialogContent: undefined,
    confirmDialogCallback: undefined,
  };

  addCharacter = () => {
    const character = this.props.screen.npcs.createCharacter({});
    this.props.screen.npcs.prependCharacter(character);
  };

  removeCharacter = id => {
    this.props.screen.npcs.removeItem(id);
  };

  addToTracker = () => {
    this.props.screen.npcs.addSelectedToTracker();
    this.setState({ allSelected: false });
  };

  toggleAllSelected = () => {
    const is_selected = !this.state.allSelected;
    this.props.screen.npcs.setSelectAll(is_selected);
    this.setState({ allSelected: is_selected });
  };

  toggleNPCsAutoInit = () => {
    this.props.screen.toggleNPCsAutoInit();
  };

  confirm(event, action, confirmed=false, content){
    if(event){
      event.stopPropagation();
      if(!confirmed){
        this.setState({
          confirmDialogCallback: (result) => this.confirm(null, () => action(), result, content),
          confirmDialogContent: content,
          confirmDialogOpened: true,
        });
        return;
      }
    }

    if(confirmed){
      action();
    }

    this.setState({
      confirmDialogOpened: false,
    });
  }

  render() {
    let pcs = this.props.screen.npcs.items;
    let autoInit = this.props.screen.setting_npcsAutoInit;

    return (
      <Fragment>
        <Typography variant="h5" gutterBottom>
          NPCs List
        </Typography>

        <Fragment>
          <FormControlLabel
            control={
              <Switch
                checked={this.state.allSelected}
                onChange={this.toggleAllSelected}
              />
            }
            label="Select all"
          />

          <FormControlLabel
            control={
              <Switch
                checked={autoInit}
                onChange={this.toggleNPCsAutoInit}
              />
            }
            label="Auto-Roll initiative"
          />

          <RightAlignedActions>
            <SpacyFab size='small' color="secondary" onClick={this.addCharacter}>
              <AddIcon/>
            </SpacyFab>
            <Fab size='small' color="secondary" onClick={this.addToTracker}>
              <SendIcon />
            </Fab>
          </RightAlignedActions>
        </Fragment>

        <List>
          {pcs.map((item) => (
            <LeftAlignedListItem
              key={`pc-${item.id}`}
              role={undefined}
              dense
              button
              onClick={() => item.toggleSelected() }
            >
              <Checkbox
                checked={item.selected}
                tabIndex={-1}
                disableRipple
              />
              <ListItemText>
                <FullWideStat item={item} propertyName='name' label='' placeholder='Character Name'/>
                <br/>
                <NarrowStat item={item} style={{marginLeft: 0}} propertyName='ac' label='AC' parser={parseInt}/>
                <NarrowStat item={item} propertyName='hp' label='HP' parser={parseInt}/>
                <WideStat item={item}
                          propertyName='init_mod'
                          parser={parseInt}
                          label='Init mod'/>
                <WideStat item={item}
                          propertyName='init'
                          parser={parseInt}
                          label='Init'/><br/>
                <FormControlLabel
                  control={
                    <Switch
                      checked={item.isMultiple()}
                      onChange={(e) => { e.preventDefault(); item.toggleMultiple()}}
                    />
                  }
                  label="Multiple copies of this NPC"
                />
                <NarrowStat item={item}
                            disabled={!item.isMultiple()}
                            propertyName='multiple'
                            parser={parseInt}
                            label='x'/>
              </ListItemText>
              <ListItemSecondaryAction>
                <IconButton aria-label="Remove"
                  onClick={((e) => this.confirm(
                    e,
                    () => this.removeCharacter(item.id),
                    false,
                    "Are you sure you want to remove this NPC?"
                  ))}>
                  <DeleteIcon />
                </IconButton>
              </ListItemSecondaryAction>
            </LeftAlignedListItem>
          ))}
        </List>

        <ConfirmationDialog open={this.state.confirmDialogOpened}
                            onClose={this.state.confirmDialogCallback}
                            content={this.state.confirmDialogContent}/>
      </Fragment>)
  }
}
