import styled from "styled-components";
import { Button } from '@material-ui/core';


const DealButton = styled(Button)`
  border: 2px solid ${props => props.theme.palette.damageControl.deal.main};
  margin-right: 4px;
  &:hover {
    border-width: 2px;
  }
`;

const HealButton = styled(Button)`
  border: 2px solid ${props => props.theme.palette.damageControl.heal.main};
  margin-right: 4px;
  color: ${props => props.theme.palette.damageControl.heal.dark};
  &:hover {
    background-color: ${props => props.theme.palette.damageControl.heal.light};
    border: 2px solid ${props => props.theme.palette.damageControl.heal.main};
  }
`;

export {DealButton, HealButton};
