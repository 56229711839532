import React, {Fragment} from "react";
import SignupAddedNewsDialog from "./dialogs";


/**
 * List of news dialogs to display for the users
 */
const NewsDialogs = () => {
  return <Fragment>
    <SignupAddedNewsDialog/>
  </Fragment>
}

export default NewsDialogs;
