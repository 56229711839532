import { createMuiTheme } from "@material-ui/core/styles";

export default createMuiTheme({
  palette: {
    primary: {
      light: '#666',
      main: '#333',
      dark: '#111',
      contrastText: '#fff',
    },
    secondary: {
      light: '#ff7961',
      main: '#f90000', //main: '#f41208',
      dark: '#ba000d',
      contrastText: '#fff',
    },
    player: {
      light: '#99ccff',
      main: '#0085f9', //main: '#f41208',
      dark: '#003265',
      contrastText: '#fff',
    },
    npc: {
      light: '#ff7961',
      main: '#f90000', //main: '#f41208',
      dark: '#ba000d',
      contrastText: '#fff',
    },
    damageControl: {
      deal: {
        light: '#ff7961',
        main: '#f90000', //main: '#f41208',
        dark: '#ba000d',
        contrastText: '#fff',
      },
      heal: {
        light: '#d4ecff',
        main: '#0085f9',
        dark: '#003265',
        contrastText: '#fff',
      },
      select: {
        light: '#fffeb3'
      }
    },
  },
  typography: {
    useNextVariants: true,
  },
});
