import styled from "styled-components";
import {CharacterStat} from "./CharacterStat";
import {Button, Fab, ListItem} from "@material-ui/core";

export const RightAlignedActions = styled.div`
  float: right;
`;

export const SpacyButton = styled(Button)`
  margin: 0 10px;
`;

export const SpacyFab = styled(Fab)`
  margin: 0 10px;
`;

export const NarrowStat = styled(CharacterStat)`
  width: 60px;
  margin-left: 20px;
`;

export const WideStat = styled(CharacterStat).attrs({ classes: "adornment" })`
  width: 100px;
  margin-left: 20px;
  
  & .adornment {
    min-width: 55px;
    width: 55px;
  }
`;

export const FullWideStat = styled(CharacterStat)`
  width: 100%;
`;


export const LeftAlignedListItem = styled(ListItem)`
  padding-left: 0;
`;
