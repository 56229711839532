import {action, observable} from "mobx";
import {loadState, setState} from "../../utils/localStorage";
import {removeFromArray} from "../../utils/utils";

export class NotesStore {
  key = 'game_notes';

  @observable items;

  constructor(root){
    this.root = root;
    this.items = [];
  }

  @action.bound
  addNote(note, save=true){
    this.items.push(note);
    if(save)
      this.saveData()
  }

  @action.bound
  createNote(){
    const note = new Note(this);
    this.addNote(note);
    return note;
  }

  @action.bound
  removeItem(note){
    this.items = removeFromArray(this.items, "id", note.id);
    this.saveData();
  }

  @action.bound
  fetchData(){
    const data = loadState(this.key);
    if(data){
      this.loadData(data)
    }
  }

  loadData(data) {
    this.items = [];
    data.forEach(noteData => {
      const note = new Note(this, noteData.id);
      note.title = noteData.title;
      note.content = noteData.content;
      this.addNote(note, false);
    });
  }

  dumpData(){
    return this.items.map(
      note => ({
          id: note.id,
          title: note.title,
          content: note.content,
    }));
  }

  saveData(){
    setState(this.key, this.dumpData());
  }
}

export class Note {
  @observable root;
  @observable id;
  @observable title = '';
  @observable content = '';

  constructor(root, id=null){
    this.root = root;
    this.id = id ? id : Date.now();
  }
}
