const hostname = window && window.location && window.location.hostname;

export const DEVEL = process.env.REACT_APP_DEVEL || false;

let API_CALLS_URL = '';
//
// if (DEVEL !== false) {
//   API_CALLS_URL = `http://${hostname}`;
// }
// else {
  API_CALLS_URL = `https://api.toady.org`;
// }

export const API_URL = `${API_CALLS_URL}`;
