import {inject, observer} from "mobx-react";
import React from "react";
import {setState, loadState} from "../../../utils/localStorage";
import {Button, Dialog, DialogContent, DialogTitle, Divider} from "@material-ui/core";
import {StyledDialogActions} from "../../styled/dialogs";



type NewsDialogProps = {
  storagekey: string,
  title: string
}


@inject('store') @observer
class NewsDialog extends React.Component<NewsDialogProps, any> {

  static defaultProps = {
    title: ""
  }

  state: any;

  constructor(props: any) {
    super(props);

    const alreadySeen = loadState(props.storagekey)
    this.state = {
      open: alreadySeen === undefined
    }
  }

  handleClose = () => {
    setState(this.props.storagekey, true)
    this.setState({open: false})
  }

  render(): React.ReactNode {
    const {title, children, ...other} = this.props

    return (
      <Dialog
        open={this.state.open}
        onClose={this.handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        {...other}
      >
        <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
        <DialogContent>
          {children}
        </DialogContent>
        <Divider/>
        <StyledDialogActions>
          <Button onClick={this.handleClose} color="primary">
            Got it!
          </Button>
        </StyledDialogActions>
      </Dialog>
    )
  }
}

//
// NewsDialog.propTypes = {
//   storagekey: PropTypes.string.isRequired,
//   title: PropTypes.string
// }
//
// NewsDialog.defaultProps = {
//   title: ""
// }

export default NewsDialog;
