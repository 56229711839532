import {number, object, optional, string, validate} from "@typeofweb/schema"


const characterSchema = object({
  name: string(),
  init: number(),
  init_mod: number(),
  hp: number(),
  hpMax: number(),
  hpDelta: number(),
  ac: number(),
  multiple: number(),
  id_prefix: optional(string()),
})

export const characterDataValidator = validate(characterSchema)
