import {action, observable} from "mobx";
import {loadState, setState} from "../../utils/localStorage";


export class Spotify {
  key = 'playlists';

  @observable items;

  constructor(root) {
    this.root = root;
    this.items = [];
  }

  @action.bound
  createPlaylist(title, uri){
    const ps = new Playlist(this);
    ps.title = title;
    ps.uri = uri;
    this.addPlaylist(ps);
    return ps;
  }

  @action.bound
  addPlaylist(item, save=true){
    this.items.push(item);
    if(save)
      this.saveData()
  }

  @action.bound
  fetchData(){
    const data = loadState(this.key);
    if(data){
      this.loadData(data)
    }
  }

  loadData(data) {
    this.items = [];
    data.forEach(data => {
      const pl = new Playlist(this, data.id);
      pl.title = data.title;
      pl.uri = data.uri;
      this.addPlaylist(pl, false);
    });
  }

  dumpData(){
    return this.items.map(
      data => ({
          id: data.id,
          title: data.title,
          uri: data.uri,
    }));
  }

  saveData(){
    setState(this.key, this.dumpData());
  }
}

export class Playlist {
  @observable root;
  @observable id;
  @observable title;
  @observable uri;

  constructor(root, id=null){
    this.root = root;
    this.id = id ? id : Date.now();
  }

  getEmbedURL(){
    if(this.uri && this.uri.includes('https')){
      return this.uri;
    }
    else if(this.uri){
      return 'https://open.spotify.com/embed/' + this.uri.split(':').slice(1).join('/');
    }
    return ''
  }
}
