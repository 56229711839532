import React from "react";
import {FullContent} from "./styled";


const About = ({ children }) => (
  <FullContent>
    <h1>Race carriages, rays of frost, dragons!</h1>
    <p>Found an issue? Send details to <a href="mailto:contact@toady.org">contact@toady.org</a></p>


    <h2>2020-08-28 - Level 4</h2>
    <h3>Leave your sign in the history with these new features</h3>

    <p>
      <strong>After months of minion work full of distractions, the Almighty Lord Accounts are here!</strong> That means you no longer have to
      depend only on your browser memory with storing data, my liege. Toady will take care of your precious prepared combat, PCs and NPCs.
      It will store your notes and even settings for the screen. You remember you can collapse unused tables, don't you sire?
    </p>
    <p>
      Until this release, all your data was stored in the memory of your browser. That means you were able to use it (and also change naturally)
      even without the internet connection. But what if you wanted to move to another computer? We know that real leaders should never be forced to repeat their orders.
      Now you can register for a free account and sync your data to our online services. Mind though, that Toady will serve you offline as before.
      All data will be stored on your browser memory like it used to. <strong>It's up to you when you hit the Sync Button.</strong>
      Our minions think this will greatly improve your experience with Toady.
    </p>

    <h3>Improved details thanks to our artisians</h3>
    <p>
      New level brings small fixes and changes in the Toady, so it can serve you swiftly, my Lord!
    </p>
    <ul>
      <li>Collapsing tables will no longer display scrollbar on its side</li>
      <li>Removing PC was confirmed as if NPC was to be removed. No more!</li>
      <li>Improved performance of combat tracker bar when moving combatants around</li>
      <li>Removed tab for Spotify playlist - soon we will replace it with proper Spotify player</li>
      <li>Sliding screen with mouse/touch is no longer supported. Instead use buttons at the bottom of the screen</li>
      <li>Manual reordering combatants on the combat tracker is now working also on touch devices.
        Touch and hold character until it turns opaque. Then you can move it around on the combat tracker
      </li>
    </ul>


    <h2>2019-06-02 - Level 3</h2>
    <h3>Brand new shiny features</h3>
    <p>
      <strong>Tracking has never been as easy as now!</strong> This update brings huge changes into our Combat Tracker feature. Now you as a DM will focus on the great fight descriptions instead of countless damage subtractions. The new combat tracker is a result of months of our analysis on how the tracking should be handled to reduce time spend on damage maths or init order. Now it is really fast and easy to apply damage, keep track of the initiative or who's next and add/remove opponents. Among new features you will find:
    </p>
    <ul>
      <li><strong>New Tracker Item Design</strong> - the tracker has now more readable items with creatures. Also it lists all minions in case of multiple creatures of one type. Alongside you will find not only their AC but also Hit Points of any individual creature in the combat. From here you can see what is the state of each of them. </li>
      <li><strong>Initiative Badge and Tracker Item color</strong> - Initiative has been moved to top left corner, so you can always check it's value quickly. Each Tracker Item has color telling you whether card belongs to PC (blue) or NPC (red). But in case you are color blind, you can always see icon in the Init badge - monster vs player.</li>
      <li><strong>New Damage Control</strong> - this is great improvement in Combat Tracker. Now you can apply damage (or healing) much faster. This will be especially useful in the mass combat damage (e.g. fireball), where you need to apply lots of damage to many different NPCs. First: select all affected NPCs with Shift Click on HP badges on the tracker. Then simply provide damage into the  Damage Taken input and apply it to the selected NPCs with hitting Enter or clicking on the button.</li>
      <li><strong>Alternative damage dealing</strong> - There's more to the new Damage Control! You can provide damage (heal) into the Damage Taken input and then apply it one-by-one to all affected NPCs (and minions) by Ctrl+Click (or Cmd+Click) on their HP badges. That's not all though! Need to apply half damage (e.g. fireball saving throw)? Use also alt key (i.e. Ctrl+Alt+Click) to apply only half damage. It will be rounded down.</li>
      <li><strong>NPC minions Detail Cards redesigned</strong> - also detail cards for NPCs got redesigned. They have new arrangement of the inputs, so the damage/heal section is quite similar to the new Damage Control.</li>
      <li><strong>Initiative tracking</strong> - some turns may take long time due to lots of storytelling. It may be hard to keep track of who's turn is currently. That's why we introduce Initiative Tracking. The button at the top left of the Combat Tracker let you now skip to the next turn in the initiative. In case of accidental click you can always click on the Init badge of the card to get back on the track.</li>
    </ul>
    <h3>Changes</h3>
    <ul>
      <li><strong>Better icons</strong> - we have changed some of the icons - thanks to the <a href="https://github.com/nagoshiashumari/Rpg-Awesome">RPG Awesome project</a> we now have more fantasy style screen</li>
      <li><strong>New PC/NPC now land on top of the list</strong> - once you have loads of NPCs or PCs adding new one required scrolling down the list. Now the new item is added at the top of the list for your convenience.</li>
      <li><strong>Once HP is below 0 it stays on 0</strong> - now if damage reduces NPC's HP below zero, it's value is set to zero. Also if the creature(s) have been eliminated they are marked wish red-white stripes so that you know when to skip their turns.</li>
    </ul>
    <h2>2019-04-25 - Level 2 revision 2</h2>
    <h3>What's new!</h3>
    <p>
     <strong>Enhanced Divine Illusion or whatever.</strong> The Schools of Magic can be easy or pretty tricky to remember and use. When player asks you what type of magic is the effect in the area, now you can quickly check newly added panel in the main screen view to decide.
    </p>
    <p>
     <strong>Notes not so forever.</strong> Now you can remove notes that are no longer needed. Just click on the trash icon. Don't worry, you will have to confirm it. But once you do, there's no coming back, so be careful anyway.
    </p>
    <h3>Ch-ch-changes!</h3>
    <p>
     <strong>Conditional resize of Conditionals.</strong> Conditions block was too large for our needs, so we have narrowed it by half to give space for Schools of Magic. It will fit properly to different screen sizes though. Along with other blocks it will better fit not matter how big your device screen is.
    </p>
    <h2>2019-04-10 - Level 2 revision 1</h2>
    <h3>What's new!</h3>
    <p>
     <strong>More damage control!</strong> Speed up damage management process by simply dealing damage to the NPCs instead of subtracting it every time. We have noticed that 90% of time Hit Points Change field in the Combat Tracker is being used for actually reducing number of hit points, so why should you put this minus symbol every time. Now you can focus on what's best in the fight: dealing damage. Just put positive number of damage and it will be properly applied.
    </p>
    <p>
     <strong>But there's more!</strong> If you want to heal an NPC, simply put negative number. The field background color and also label will let you know, that you are healing now instead of dealing damage.
    </p>
    <p>
     <strong>ENTER the change!</strong> Previously in order to confirm damage/healing you had to click on the Apply HP button in order to update current Hit Points. This is no longer the only way. Now you can put number into the Damage Taken field and hit ENTER to apply it quickly. Now your updates will be even faster.
    </p>
    <h2>2019-04-10 - Level 2</h2>
    <h3>What's new!</h3>
    <p><strong>API server is here!</strong> - now some data are pulled from our own API server, so we can go fully to the SSL and you no longer have to choose between conditions or playlists working properly.</p>
    <p>In the future API server will bring more features - among them you will be able to sign up and sync your prepared materials for the game between multiple machines.</p>
    <p><strong>Fast Spells metamagic feat picked for the new level!</strong> Spells can now be listed by only level or the name first letter. New filter will let you decide how to find spell. Bot filters can be used as previously to find specific level spells starting with given letter.</p>
    <h2>2019-01-14 - Level 1 revision 4</h2>
    <h3>What's new!</h3>
    <p><strong>It's levelup time coming!</strong> Since this update we are starting to version app with levels naming. Big changes will be marked with new level verison. Smaller updates will come in form of classic dot separated numbers.</p>
    <p><strong><em>Music gives a soul to the universe, wings to the mind, flight to the imagination and life to everything.</em></strong> ― Plato</p>
    <p>Spotify Playlists are on board now! You can add your favorite Playlists to the screen and easily switch music during your game session. So go on! Flood your players ears with battle music when they charge at the giants. Make them cry to the sad song when their best friend NPC dies. In future we plan to integrate closer with Spotify, so you will be able to chose playing device and change volume via the screen.
    </p>
    <p><strong>Important!</strong> Spotify works only with SSL connection so be sure to have https in the address when you connect with toady (we will start to enforce it very soon). There may be also issues with playing full songs from Spotify if you block 3rd party cookies in your browser, so make sure you don't block Spotify and Toady.</p>
    <h2>2018-09-09</h2>
    <h3>What's new</h3>
    <p><strong>Less combat tracker after combat</strong></p>
    <ul>
      <li> Tired of removing each character in the tracker one-by-one? No more click-click-click. Make Total Party (and Non-Party) Kill with one button "Clear Tracker" added in this update.</li>
      <li>But in case you want to remove one-by-one anyway, now it is faster. Click trash icon and then confirm with click on the confirmation icon in the same place.</li>
      <li>No more love for PCs! Their hearts with HP are gone from tracker, since you're not the one who should track their life energy.</li>
    </ul>
    <h2>2018-09-07</h2>
    <h3>What's new!</h3>
    <p><strong>Notes + Notes + Notes</strong></p>
    <ul>
      <li>Because making notes within previous notes is simply a mess, now you can make multiple notes</li>
      <li>You note is a king! Give it a title as well.</li>
      <li>Having multiple notes can get problematic over time, so why not check creation time above the title?</li>
    </ul>
    <h2>2018-08-21</h2>
    <p>Combat tracker changes.</p>
    <ul>
      <li>Let's go the opposite way. NPC and PC list moved to the right side drawer opened with buttons above the tracker.</li>
      <li>Current NPC/PC stats moved from drawer to the main content below tracker list</li>
      <li>Added labels and notes for the NPCs so you can note more than just HP</li>
      <li>removed HP and Init mod from PCs - players should keep track of these instead</li>
      <li><strong>It is almost dead? Not even close!</strong> Introduced <em>Reset to max HP</em> button, which sets HP to it's max value quickly</li>
      <li><strong>no more scrolls</strong> - minor UX fixes where scrolling is not really magical</li>
      <li><strong>Green or Red?</strong> The background color of HP change field gives quick insight into the HP changes nature.</li>
    </ul>
    <h2>2018-08-18</h2>
    <p><strong>Combat tracker is here!</strong> - now you can keep your fight on this beautiful, virtual track. Never miss the NPC again in the round.</p>
    <ul>
      <li>You can define your own PC/NPCs - even multiple if you want to send a horde against players</li>
      <li>You can grab and move around your (N)PC on the tracker - because <strong>init roll doesn't need to be fair</strong></li>
      <li>But in case you want to roll init for your NPC while adding to trakcer - it is available by default</li>
      <li>Click on any NPC on the tracker and change their HP - <strong>let the blood be spilled!</strong></li>
    </ul>
    {children}
  </FullContent>
);

export default About;
