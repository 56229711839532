import styled from "styled-components";
import {inject, observer} from "mobx-react";
import React, {Component, Fragment} from "react";
import DeleteConfirmationButton from "../buttons/DeleteConfirmationButton";


import { Paper, Typography } from '@material-ui/core';


const CreatureItem = styled(Paper)`
  user-select: none;
  padding: 5px 5px 20px;
  margin: 0;
  min-width: 200px;
  margin-right: ${props => props.theme.spacing(3)}px;
`;

const PlayerItem = styled(CreatureItem)`
  border: 2px solid ${props => props.theme.palette.player.light}
`;

const NPCItem = styled(CreatureItem)`
  border: 2px solid ${props => props.theme.palette.npc.light}
`;

const NPCItemInactive = styled(NPCItem)`
  background: repeating-linear-gradient(-45deg, #f9f9f9, #f9f9f9 10px, #ff7961 10px, #ff7961 20px);
`

const RemoveIcon = styled.a`
  float: right;
`;

const CreatureName = styled(Typography)`
  padding: 0 ${props => props.theme.spacing(1)}px 0;
  min-height: ${props => props.theme.spacing(2)}px;
  margin-bottom: ${props => props.theme.spacing(1)}px;
  font-size: 18px;
  font-weight: bold;
  display: inline-block;
  max-width: 200px;
  margin-right: 60px;
`;

const CreatureCopyName = styled(CreatureName)`
  font-weight: normal;
`;


const StatBadge = styled.div`
  border: 2px solid #aaa;
  padding: 5px 10px;
  display: inline-block;
  background: white;
`;

const InitStatBadge = styled(StatBadge)`
  position: relative;
  top: -16px;
  left: -16px;
`;

const ActiveInitStatBadge = styled(InitStatBadge)`
  color: white;
  background: ${props => props.theme.palette.primary.dark};
  border-color: ${props => props.theme.palette.primary.dark};
`;

const DefenceStatBadge = styled(StatBadge)`
  position: absolute;
  right: -16px;
`;

const HPStatBadge = DefenceStatBadge;

const HPStatBadgeSelected = styled(DefenceStatBadge)`
  background: ${props => props.theme.palette.damageControl.select.light};
`;

const RightIcon = styled.i`
  padding-left: 10px;
`;

const LeftIcon = styled.i`
  padding-right: 10px;
`;

const Row = styled.div`
  position: relative;
  margin-bottom: 4px;
  &.empty{
    margin-bottom: ${props => props.theme.spacing(3)}px;
  }
`;

const getItemStyle = (isDragging, draggableStyle) => ({
  // change background colour if dragging
  opacity: isDragging ? "0.8" : "1.0",
});


const InitBadge = (props) => {
  const {className, current = false, item, ...other} = props;
  const cName = className || (item.type === 'PC' && 'ra ra-player') || 'ra ra-monster-skull';
  let BadgeComponent = InitStatBadge;
  if (current) {
    BadgeComponent = ActiveInitStatBadge;
  }
  return (<BadgeComponent {...other}>
    {item.character.init}
    <RightIcon className={cName}/>
  </BadgeComponent>)
};

const DefenceBadge = (props) => {
  return (<DefenceStatBadge>
    <LeftIcon className='ra ra-heavy-shield'/>
    {props.value}
  </DefenceStatBadge>)
};

const HPBadge = (props) => {
  let BadgeVariant = HPStatBadge;
  if(props.selected === true) {
    BadgeVariant = HPStatBadgeSelected;
  }

  return (<BadgeVariant>
    <LeftIcon className='ra ra-health'/>
    {props.value}
  </BadgeVariant>)
};


@inject('store') @observer
class TrackerItem extends Component {

  selectCreature = () => {
    this.props.store.screen.tracker.selected = this.props.item;
  };

  remove = () => {
    if(this.props.store.screen.tracker.selected && this.props.item.id === this.props.store.screen.tracker.selected.id){
      this.props.store.screen.tracker.selected = null;
    }
    this.props.store.screen.tracker.removeItem(this.props.item.id);
  };

  handleCopyClick = copy => e => {
    if(e.shiftKey){
      e.stopPropagation();
      this.props.store.screen.damageControl.toggleSelect(copy);
    }
    else if(e.ctrlKey || e.metaKey){
      e.stopPropagation();
      if(e.altKey){
        copy.applyDamage(Math.floor(parseInt(this.props.store.screen.damageControl.HPChange)/2));
      }
      else {
        copy.applyDamage(this.props.store.screen.damageControl.HPChange);
      }
    }
  };

  setInit = item => e => {
    e.stopPropagation();
    this.props.store.screen.tracker.setInit(item);
  };

  render() {
    const {item, snapshot, provided} = this.props;

    const current = this.props.store.screen.tracker.current === item;



    let CreatureComponent = item.type === 'NPC' ? NPCItem : PlayerItem;

    if(item.type === 'NPC' && item.copies.every(copy => copy.hp <= 0)) {
      CreatureComponent = NPCItemInactive;
    }

    return (
      <CreatureComponent
        style={getItemStyle(
          snapshot.isDragging,
          provided.draggableProps.style
      )}
        onClick={this.selectCreature}
      >

        <Row>
          <InitBadge item={item} current={current} onClick={this.setInit(item)}/>
          <DeleteConfirmationButton component={RemoveIcon} onConfirm={this.remove}/>
        </Row>

        <Row>
          <CreatureName>
            {item.character.name}
          </CreatureName>

          <DefenceBadge value={item.character.ac} />
        </Row>

        {item.type === 'NPC' && item.copies.length >= 1 &&
          <Fragment>{item.copies.map((copy, index) =>
            <Row key={`creature-copy-${index}`} onClick={this.handleCopyClick(copy)} className={item.copies.length === 1 && 'empty'}>
              {item.copies.length > 1 &&
                <CreatureCopyName>
                  {copy.label || `${item.character.name} #${index + 1}`}
                </CreatureCopyName>
              }
              <HPBadge value={copy.hp} selected={copy.selected}/>
            </Row>
          )}
          </Fragment>
        }
      </CreatureComponent>
    )
  }
}

export default TrackerItem;
