import {withStyles} from "@material-ui/core";
import {inject, observer} from "mobx-react";
import {Component} from "react";
import {DragDropContext, Droppable} from "react-beautiful-dnd";
import React from "react";
import TrackerItemList from "./TrackerItemList";
import TrackerItem from "./TrackerItem";

const grid = 8;

const styles = (theme) => ({
  list: {
    display: "flex",
    padding: grid * 2,
    overflow: "auto",
  },
});


const getListStyle = isDraggingOver => ({
  background: isDraggingOver ? "repeating-linear-gradient(-45deg, #f5ffc4, #f5ffc4 10px, #ddd 10px, #ddd 20px)" :
    "repeating-linear-gradient(-45deg, #eee, #eee 10px, #f9f9f9 10px, #f9f9f9 20px)",
});
//#e0ff99 #fffe58

// TODO: Remove unused
// const DraggablePlaceholder = styled.div`
//   border: 3px dashed red;
//   border-radius: 5px;
//   background: rgba(255,255,255,0.6);
//   text-align: center;
//   font-size: 24px;
//   font-weight: bold;
//   color: red;
// `;


@withStyles(styles, { withTheme: true})
@inject('store') @observer
export default class CombatTrackerBar extends Component {

  constructor(props) {
    super(props);
    this.onDragEnd = this.onDragEnd.bind(this);
    this.mouseEnter = this.mouseEnter.bind(this);
    this.mouseLeave = this.mouseLeave.bind(this);
  }

  mouseEnter = () => {
    this.props.store.screen.canSwipe = false;
  };

  mouseLeave = () => {
    this.props.store.screen.canSwipe = true;
  };

  onDragStart = () => {

    this.props.store.screen.canSwipe = false;

    if (window.navigator.vibrate) {
      window.navigator.vibrate(50);
    }

    if(this.props.store.screen.getAutoSort()){
      this.props.store.screen.toggleAutoSort();
    }
  };

  onDragEnd = (result) => {
    this.props.store.screen.canSwipe = true;

    // dropped outside the list
    if (!result.destination) {
      return;
    }

    this.props.store.screen.tracker.reorder(
      result.source.index,
      result.destination.index);
  };

  render() {
    const { classes, items } = this.props;

    return (
      <DragDropContext
        onDragEnd={this.onDragEnd}
        onDragStart={this.onDragStart}
      >
        <div
          onMouseEnter={this.mouseEnter}
          onMouseLeave={this.mouseLeave}>
          <Droppable droppableId="droppable" direction="horizontal"
                     renderClone={(provided, snapshot, rubric) => (
                           <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                          >
                            <TrackerItem item={items[rubric.source.index]} snapshot={snapshot} provided={provided} />
                          </div>
                     )}
          >
            {(provided, snapshot) => (
              <div
                ref={provided.innerRef}
                className={classes.list}
                style={getListStyle(snapshot.isDraggingOver)}
                {...provided.droppableProps}
              >
                <TrackerItemList items={items} provided={provided} snapshot={snapshot}/>
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </div>
      </DragDropContext>
    )
  }
}
