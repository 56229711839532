import React, {Component} from "react";
import {FormControl, InputAdornment, TextField} from '@material-ui/core';
import {Character} from "../stores";
import {observer} from "mobx-react";

export interface CharacterStatProps {
  item: Character,
  propertyName: string,
  placeholder?: string,
  label?: string,
  classes?: {
    adornment?: string
  },
  parser?(string): number | string
}


@observer
export class CharacterStat extends Component<CharacterStatProps> {

  render() {
    const {item, propertyName, label, placeholder, classes, parser, ...props} = this.props;

    return (
      <FormControl
        {...props}
      >
        <TextField
          id={`adornment-ac-${item.id}`}
          value={item[propertyName]}
          onClick={(e) => {
            e.stopPropagation();
            e.preventDefault();
          }}
          onChange={(e) => {
            item[propertyName] = parser ? parser(e.target.value) : e.target.value;
            item.saveData()
          }}
          placeholder={placeholder}
          InputProps={{
            'aria-label': label,
            startAdornment: !!label && <InputAdornment classes={{ root: classes?.adornment }} position="start">{label}</InputAdornment>
          }}
        />
      </FormControl>
    )
  }
}
