import {observable} from "mobx";
import {api} from "../../../api/agent";

export class Spells {
  @observable root = null;
  @observable spellsData = [];
  @observable filtered = [];
  @observable currentLevel = null;
  @observable currentLetter = null;

  constructor(root) {
    this.root = root;
  }

  filterSpellName = (spell) => {
    if(spell.name)
      return spell.name.startsWith(this.currentLetter);
    return null;
  };

  filterSpellLevel = (spell) => {
    let level = this.currentLevel;
    if(level === 0){
      level = 'C'
    }

    if(spell.level)
      return spell.level.startsWith(level);
    return null;
  };

  filterSpells(){
    let filtered = this.spellsData.slice(0);
    if(this.currentLevel !== null){
      filtered = filtered.filter(this.filterSpellLevel)
    }
    if(this.currentLetter !== null){
      filtered = filtered.filter(this.filterSpellName)
    }
    return filtered;
  }

  loadFilteredSpells(){
    if (this.spellsData.length) {
      this.filtered = this.filterSpells();
    }
    else {
      this.fetchSpells().then(() => {this.filtered = this.filterSpells()});
    }
  }

  fetchSpells(){
    return api.get('/v1/screens/5e/spells/').then(response => {
      this.spellsData = response.results;
    });
  }
}
