import React from 'react';
import TableIcon from '@material-ui/icons/TableChart';
import EditIcon from '@material-ui/icons/Edit';
import {inject, observer} from "mobx-react";
import styled from "styled-components";
import "rpg-awesome/css/rpg-awesome.css";
import ReactGA from "react-ga";
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'


import { BottomNavigation, BottomNavigationAction } from '@material-ui/core';


const FixedBottomNavigation = styled(BottomNavigation)`
  position: fixed;
  bottom: 0;
  width: 100%;
`;

const TABS_MAP = {
  0: "tables",
  1: "combat-tracker",
  // 2: 'syrinscape',
  // 3: "music",
  2: "spells",
  3: "notes"
};

function reportTab(value) {
  ReactGA.modalview(`/tab/${TABS_MAP[value]}`);
}


@inject('tabsStore') @observer
class LabelBottomNavigation extends React.Component {

  handleChange = (event, value) => {
    reportTab(value);
    this.setState({ value });
    this.props.tabsStore.setTab(value);
  };

  getCurrentTab(){
    return this.props.tabsStore.currentTab.toString();
  }

  render() {
    return (
      <FixedBottomNavigation value={ this.getCurrentTab() } onChange={this.handleChange}>
        <BottomNavigationAction label="Tables" value="0" icon={<TableIcon />} />
        <BottomNavigationAction label="Combat Tracker" value="1" icon={<i className='ra ra-crossed-swords ra-2x'/>} />
        {/*<BottomNavigationAction label="Syrinscape" value="2" icon={<FontAwesomeIcon icon="drum" size="2x"/>} />*/}
        {/*<BottomNavigationAction label="Spotify" value="3" icon={<FontAwesomeIcon icon={['fab', 'spotify']} size="2x"/>} />*/}
        <BottomNavigationAction label="Spells" value="2" icon={<i className='ra ra-fairy-wand ra-2x' />} />
        <BottomNavigationAction label="Notes" value="3" icon={<EditIcon />} />
      </FixedBottomNavigation>
    );
  }
}

export default LabelBottomNavigation;
