import styled from "styled-components";
import React, {Component, Fragment} from 'react';
import {inject, observer} from "mobx-react";
import SignInDialog from "./dialogs/SignInDialog";
import SignUpDialog from "./dialogs/SignUpDialog";
import {api} from "../../api/agent";
import SignUpVerificationDialog from "./dialogs/SignUpVerificationDialog";
import {Button} from '@material-ui/core';
import Sync from "./sync/Sync";
import CircularProgress from '@material-ui/core/CircularProgress';

const AppBarButton = styled(Button)`
  margin-right: 12px;
`;


const ActionsContainer = styled.div`
  margin-right: 12px
`;


@inject('store') @observer
class AccountNavigation extends Component {

  signIn = () => {
    this.props.store.signInDialogOpen = true;
  };

  signOut = () => {
    api.Auth.signout()
    this.props.store.signedIn = false;
  };

  render() {
    if(this.props.store.signedIn === undefined){
      return <CircularProgress color="secondary" />
    } else if (this.props.store.signedIn === true) {
      return (
        <Fragment>
          <ActionsContainer>
            <Sync/>
          </ActionsContainer>
          <AppBarButton color="inherit" onClick={this.signOut}>Sign out</AppBarButton>
        </Fragment>
      )
    } else {
      return (
        <Fragment>
          <AppBarButton color="inherit" onClick={this.signIn}>Sign in</AppBarButton>
          <SignInDialog/>
          <SignUpDialog />
          <SignUpVerificationDialog/>
        </Fragment>
      )
    }
  }
}

export default AccountNavigation;
