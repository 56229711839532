import styled from "styled-components";
import {observer} from "mobx-react";
import React, {Component} from "react";
import {DealButton, HealButton} from "../../../components/common/buttons/DamageButtons";
import DamageFormControl from "../../../components/common/DamageFormControl";


import {Button, FormControl, Grid, Input, Paper, Typography} from '@material-ui/core';
import {TrackerCharacterGroup} from "./stores";


const LabelFormControl = styled(FormControl)`
  margin-bottom: 16px;
  width: 100%;
`;

const CreatureName = styled(Typography)`
  margin-bottom: 16px;
`;

const LocalDamageControl = styled(Grid)`
  text-align: center;
`;

const Row = styled.div`
  width: 100%;
`;

const ResetToMaxButton = styled(Button)`
  border: 2px solid ${props => props.theme.palette.damageControl.heal.main};
  color: ${props => props.theme.palette.damageControl.heal.main};
  font-weight: bold;
  margin-top: ${props => props.theme.spacing(1)}px;
  &:hover {
    background-color: ${props => props.theme.palette.damageControl.heal.light};
    border: 2px solid ${props => props.theme.palette.damageControl.heal.main};
    color: ${props => props.theme.palette.damageControl.heal.main};  
  }
`;

const ExtraNotes = styled(Input)`
  margin: ${props => props.theme.spacing(1)}px 0 ${props => props.theme.spacing(1) * 2}px;
`;


interface CombatTrackerDetailProps {
  selectedItem: TrackerCharacterGroup
}


@observer
export class CombatTrackerDetail extends Component<CombatTrackerDetailProps> {

  HEAL_CHANGE_BUTTONS = [-1, -5, -10];
  DEAL_CHANGE_BUTTONS = [1, 5, 10];

  setCopyData = (e, item, field) => {
    item[field] = e.target.value;
    item.root.saveData();
  };

  updateHP = (e, copy) => {
    e.preventDefault();
    copy.applyHPDelta();
  };

  resetCopyHP = copy => e => {
    copy.hp = copy.hpMax;
    copy.hpDelta = 0;
    copy.root.saveData()
  };

  render() {
  const item = this.props.selectedItem;

  if(!item)
    return null;
  return (
    <div style={{ marginTop: 20}}>
      <CreatureName variant='h4' component='h1'>
        {item.character.name}
      </CreatureName>

      <Grid container spacing={1}>
      {item.copies.map((copy, index) => (
        <Grid item xs={12} sm={6} lg={4} key={`character-copy-${index}`} >
        <Paper style={{ padding: 16, marginBottom: 20}}>
          {item.type === 'NPC' ?
          (<div>
            <LabelFormControl>
              <Input
                id={`character-detail-label-${item.id}-${index}`}
                value={copy.label}
                onChange={(e) => this.setCopyData(e, copy, 'label')}
                placeholder={`${item.character.name} #${index+1}`}
              />
            </LabelFormControl>
            <ExtraNotes
              multiline
              fullWidth
              rows="3"
              placeholder="Extra notes..."
              onChange={(e) => this.setCopyData(e, copy, 'notes')}
              defaultValue={ copy.notes }/>

            <Grid container spacing={1}>
              <Grid item xs={12} md={4}>
                <Grid container spacing={1}>
                  <Grid item xs={12}>
                    <Typography variant="body1">
                      Hit Points
                    </Typography>
                    <FormControl
                      aria-describedby="weight-helper-text"
                    >
                      <Input
                        id={`character-detail-hp-${item.id}-${index}`}
                        value={copy.hp - copy.hpDelta}
                        onChange={(e) => { copy.hp = parseInt(e.target.value); copy.hpDelta = 0; } }
                      />
                    </FormControl>
                  </Grid>

                  <Grid item xs={12}>
                    <Typography variant="body1">
                      Max Hit Points
                    </Typography>

                    <FormControl
                      aria-describedby="weight-helper-text"
                    >
                      <Input
                        id={`character-detail-hp-delta-${item.id}-${index}`}
                        value={copy.hpMax}
                        type="number"
                        onChange={(e) => this.setCopyData(e, copy, 'hpMax') }
                      />
                    </FormControl>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} md={8}>
                <LocalDamageControl container spacing={1}>
                  <Row>
                    <form onSubmit={(e) => this.updateHP(e, copy)}>
                    <Typography variant="body1">
                      Damage Taken {copy.hpDelta < 0 && `(Healing)`}
                    </Typography>

                    <DamageFormControl
                      aria-describedby="weight-helper-text"
                      style={{
                        backgroundColor: copy.hpDelta !== 0 ? (copy.hpDelta > 0 ? '#ffcbc9' : '#d4ecff') : '',
                        borderColor: copy.hpDelta !== 0 ? (copy.hpDelta > 0 ? '#f90000' : '#0085f9') : '',
                      }}
                    >
                      <Input
                        id={`character-detail-hp-delta-${item.id}-${index}`}
                        value={copy.hpDelta}
                        type="number"
                        onChange={(e) => this.setCopyData(e, copy, 'hpDelta') }
                      />
                    </DamageFormControl>
                    </form>
                  </Row>

                  <Row>
                    <Typography variant='subtitle1'><i className='ra ra-bleeding-hearts' /> DEAL</Typography>
                    {this.DEAL_CHANGE_BUTTONS.map(button =>
                      (
                        <DealButton key={`hpdelta-heal-${item.id}-${button}`} variant="outlined" size="small" color="secondary" onClick={() => copy.changeHPDelta(button)}>
                          {button}
                        </DealButton>
                      )
                    )}
                  </Row>

                  <Row>
                    <Typography variant='subtitle1'><i className='ra ra-heart-bottle' /> HEAL</Typography>
                    {this.HEAL_CHANGE_BUTTONS.map(button =>
                      (
                        <HealButton key={`hpdelta-heal-${item.id}-${button}`} variant="outlined" size="small" color="secondary" onClick={() => copy.changeHPDelta(button)}>
                          {button}
                        </HealButton>
                      )
                    )}

                    <ResetToMaxButton variant="outlined" size="small" color="secondary"
                            onClick={this.resetCopyHP(copy)}>
                      Reset to max HP
                    </ResetToMaxButton>

                  </Row>

                  <Row>
                    <Button
                      variant="contained"
                      color="secondary"
                      onClick={() => copy.applyHPDelta() }
                      style={{ marginTop: 20}}
                    >
                      Apply {copy.hpDelta >= 0 ? 'damage' : 'healing'}
                    </Button>
                  </Row>
                </LocalDamageControl>
              </Grid>
            </Grid>
        </div>)
        :
            <ExtraNotes
              multiline
              fullWidth
              rows="3"
              placeholder="Extra notes..."
              onChange={(e) => this.setCopyData(e, copy, 'notes')}
              defaultValue={ copy.notes }/>
          }
        </Paper>
        </Grid>
      ))}
      </Grid>
    </div>)
  };
}
