import React from "react";
import DoneIcon from "@material-ui/icons/Done";
import { Button } from '@material-ui/core';

export default class ConfirmationButton extends React.Component {
  DEFAULT_TIMEOUT = 2000;

  state = {
    inConfirmation: false,
    timeout: null,
  };

  confirm = (e) => {
    const {timeout = this.DEFAULT_TIMEOUT} = this.props;
    e.stopPropagation();
    this.setState({inConfirmation: true});
    const timeout_obj = setTimeout(() => {
      this.setState({inConfirmation: false})
    }, timeout);
    this.setState({timeout: timeout_obj});
  };

  action = (e) => {
    const {onConfirm} = this.props;
    e.stopPropagation();
    clearTimeout(this.state.timeout);
    this.setState({inConfirmation: false});
    onConfirm();
  };

  render() {
    const {
      component = Button,
      confirmationIcon = <DoneIcon/>,
      icon,
      label,
      onConfirm,
      ...other
    } = this.props;

    const Component = component;

    if(this.state.inConfirmation)
      return <Component aria-label={label}
                     onClick={this.action}
                     {...other}>
              {confirmationIcon}
            </Component>;
    else
      return <Component aria-label={label}
                     onClick={this.confirm}
                     {...other}>
                {icon}
              </Component>;
  }
}
