import styled from "styled-components";
import { Grid } from '@material-ui/core';

export const MainGridContainer = styled(Grid)`
  margin: 
    0 -${props => props.theme.spacing(1)}px 0;
  padding: 
    0
    ${props => props.theme.spacing(1)}px
    ${props => props.theme.spacing(1)}px;
`;

export const Container = styled.div`
  width: 100%;
  overflow-x: auto;
  position: relative;
`;
