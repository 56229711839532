import styled from "styled-components";

const FullContent = styled.div`
    padding: 70px 10px 0;
    margin: auto;
    max-width: 700px;
`;

export {
  FullContent
}
