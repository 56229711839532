import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import CircularProgress from "@material-ui/core/CircularProgress";
import Typography from "@material-ui/core/Typography";
import styled from "styled-components";

const AppBarFontAwesomeIcon = styled(FontAwesomeIcon)`
  color: white;
  font-size: 1.4em;
`;

const SyncProgress = styled(CircularProgress)`
  position: absolute;
  top: -5px;
  left: 0px;
  z-index: 1;
  width: 36px;
  height: 36px;
`

const SyncButtonWrapper = styled.div`
  position: relative;
  display: inline;
  margin-left: 10px;
`

const LastSyncInfo = styled(Typography)`
  display: inline;
`

export {
  AppBarFontAwesomeIcon,
  SyncProgress,
  SyncButtonWrapper,
  LastSyncInfo
}
