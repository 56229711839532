import {DataRow, DataCell, ScrollableXSpacedBottom} from "../styled/tables";
import CollapsibleBlock from "./CollapsibleBlock";
import {Note} from "../styled/typography";
import React from "react";

import { Table, TableHead, TableBody } from '@material-ui/core';

const ScreenTable = (props) => {
  const {title='', header=null, body=[], note=null} = props;
  return (
    <ScrollableXSpacedBottom>
      <CollapsibleBlock title={title} id={`id ${title}`}>
        <Table>
          {header && (
            <TableHead>
              <DataRow>
                {header.map((headingCell, index) => {
                  return (
                    <DataCell component="th" scope="row" key={`table${title}-thead-${index}`}>
                      {headingCell}
                    </DataCell>
                  )
                })}
              </DataRow>
            </TableHead>
          )}
          <TableBody>
            {body.map((row, row_index) => (
              <DataRow key={`table${title}row${row_index}`}>
                {row.map((cell, index) => (
                  <DataCell scope="row" key={`table${title}row${row_index}cell${index}`}>
                    {cell}
                  </DataCell>))}
              </DataRow>
            ))}
          </TableBody>
        </Table>
        {note && (<Note>{note}</Note>)}
      </CollapsibleBlock>
    </ScrollableXSpacedBottom>
  )
};

export default ScreenTable;
