import {action, observable} from "mobx";
import {api} from "../../../../api/agent";


export class PanelsListItem {
  @observable index;
  @observable name;
  @observable effects;

  constructor(index, name, desc){
    this.index = parseInt(index, 10);
    this.name = name;
    this.desc = desc;
  }
}


export class PanelsListStore {
  @observable root = null;
  @observable currentPanel = null;
  @observable items = [];
  ItemClass = PanelsListItem;

  constructor(root){
    this.root = root;
    this.url = null;
  }

  fetchData(){
    this.items = [];
    return api.get(this.url)
      .then((response) => {
        response.results.map(item => this.createItem(item))
      })
      .catch((err) => {
        console.log("Error in axios", err);
      });
  }

  createItem(item){
    this.items.push(new this.ItemClass(
      parseInt(item.index, 10),
      item.name,
      item.desc,
    ));
  }

  @action.bound
  setPanel(number){
    this.currentPanel = number;
  }

  @action.bound
  getCurrent(){
    return this.currentPanel;
  }

  @action.bound
  isCurrent(number){
    return this.getCurrent() === number;
  }
}
