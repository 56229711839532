import styled from "styled-components";
import React from "react";
import ArrowBackIos from "@material-ui/icons/ArrowBackIos";
import ArrowForwardIos from "@material-ui/icons/ArrowForwardIos";
import PropTypes from 'prop-types';

import { Paper, Typography, Button } from '@material-ui/core';

const HintPaper = styled(Paper)`
  padding: ${props => props.theme.spacing(1)}px;
`;

const HintTitle = styled(Typography)`
  font-size: 0.9em;
  font-weight: bold;
`;

const HintText = styled(Typography)`
  font-size: 0.8em;
  font-style: italic;
`;

const HintsNav = styled.div`
  margin-top: ${props => props.theme.spacing(1)}px;
  text-align: center;
`;

const HintCounter = styled.span`
  color: ${props => props.theme.palette.primary.light};
`;

export default class HintsCard extends React.Component {
  state = {
    current: 0,
    timeout: null,
  };

  componentDidMount(){
    this.setAutoplay();
  }

  componentWillUnmount() {
    this.clearAutoplay();
  }

  autoplay = () => {
    this.selectNext();
    this.setAutoplay();
  };

  clearAutoplay = () => {
    if (this.state.timeout) {
      clearTimeout(this.state.timeout);
    }
  };

  setAutoplay = () => {
    const {autoplay = false, autoplayDelay = 5000} = this.props;
    if (autoplay) {
      this.setState({timeout: setTimeout(this.autoplay, autoplayDelay)});
    }
  };

  getCurrent = () => this.props.hints[this.state.current % this.props.hints.length];

  getCurrentNumber = () => (this.state.current % this.props.hints.length) + 1;

  selectNext = () => {
    this.setState({current: this.state.current + 1})
  };

  selectPrev = () => {
    if (!this.state.current) {
      this.setState({current: this.props.hints.length - 1})
    }
    else {
      this.setState({current: this.state.current - 1 })
    }
  };

  render() {
    return (
      <HintPaper onMouseEnter={this.clearAutoplay} onMouseLeave={this.setAutoplay}>
        <HintTitle variant='body1'>
          <HintCounter>({this.getCurrentNumber()}/{this.props.hints.length})</HintCounter>&nbsp;
          {this.getCurrent().title}
        </HintTitle>
        <HintText variant='body1'>
          {this.getCurrent().text}
        </HintText>
        <HintsNav>
          <Button size='small' onClick={this.selectPrev}>
            <ArrowBackIos />
          </Button>
          <Button size='small' onClick={this.selectNext}>
            <ArrowForwardIos />
          </Button>
        </HintsNav>
      </HintPaper>
    )
  }
}

HintsCard.propTypes = {
  autoplay: PropTypes.bool,
  autoplayDelay: PropTypes.number,
  hints: PropTypes.arrayOf(PropTypes.object),
};
