import React from 'react';
import PropTypes from "prop-types";
import {loadState, setState} from "../../utils/localStorage";
import styled from "styled-components";
import ReactGA from "react-ga";


import {ExpansionPanel, ExpansionPanelSummary, Typography} from '@material-ui/core';


const Summary = styled(props => (
  <ExpansionPanelSummary classes={{content: 'content', root: 'root', expanded: 'expanded'}} {...props}/>))`
    background: ${props => props.theme.palette.secondary.main};
    color: ${props => props.theme.palette.secondary.contrastText};
    margin: 0;
    height: 30px;
    overflow: hidden;
    
  &.root {
    height: 30px;
    min-height: 30px !important;
    padding: 0 5px;
  }
  
  &.expanded {
    margin: 0;
  }
  
  &.content {
    margin: 0 0 !important;
    text-align: center;
  }
`;
Summary.muiName = ExpansionPanelSummary.muiName;


const Header = styled(Typography)`
    text-align: center;
    color: #fff;
    margin: 0;
    padding: 0;
    width: 100%;
    &:last-child {
      padding-right: 0;
    };
`;


class CollapsibleBlock extends React.Component {

  state = {
    expanded: true,
  };

  constructor(props){
    super(props);
    this.persistedState = loadState(this.props.id);
    this.state = {
      expanded: this.checkExpanded(),
    }
  }


  setExpand = () => {
    let expand = !this.state.expanded;
    ReactGA.event({
      category: 'Screen',
      action: 'Toggle Expanded for Block',
      value: expand ? 1 : 0,
      label: this.props.title
    });
    setState(this.props.id, { expanded: expand });
    this.setState({ expanded: expand });
  };

  checkExpanded(){
    if(this.persistedState){
      let expanded_state = this.persistedState.expanded;
      if(expanded_state !== undefined)
        return expanded_state;
    }
    return true;
  }

  render() {
    const { title, children } = this.props;

    return (
        <ExpansionPanel expanded={ this.state.expanded } onChange={ this.setExpand }>
          <Summary>
            <Header>{ title }</Header>
          </Summary>
          <div>{children}</div>
        </ExpansionPanel>
    );
  }
}

CollapsibleBlock.propTypes = {
  classes: PropTypes.object,
  theme: PropTypes.object,
  title: PropTypes.string,
};

export default CollapsibleBlock;
