import {action, observable} from "mobx";
import {randomIntFromInterval} from "../../../../utils/utils";


export class Character {
  @observable root: any;
  @observable id!: string | number;

  @observable name: string = '';
  @observable init: number = 0;
  @observable init_mod: number = 0;
  @observable hp: number = 0;
  @observable hpMax: number = 0;
  @observable hpDelta: number = 0;
  @observable ac: number = 0;
  @observable type?: string = undefined;

  @observable multiple: number = 1;
  @observable selected: boolean = false;
  @observable id_prefix?: string = undefined;

  constructor(root: any, id: string | number){
    this.root = root;
    this.id = id;
  }

  @action.bound
  saveData(){
    this.root.saveData()
  }

  getKeyId(): string{
    return `${this.id_prefix}tracker-item-key-${this.id}`;
  }

  @action.bound
  toggleSelected(){
    this.selected = !this.selected;
  }

  @action.bound
  rollInit(){
    const random = randomIntFromInterval(1, 20);
    this.init = random + this.init_mod;
  }

  isMultiple(): boolean{
    return this.multiple > 1;
  }

  @action.bound
  toggleMultiple(){
    if(this.isMultiple())
      this.multiple = 1;
    else
      this.multiple = 2;
    this.saveData();
  }

  @action.bound
  changeHPDelta(difference){
    this.hpDelta = this.hpDelta + difference;
  }

  @action.bound
  applyHPDelta(){
    this.hp = this.hp - this.hpDelta;
    this.hpDelta = 0;
    this.root.saveData();
  }
}

