import React, {Component} from 'react';
import {AppStore, NavigationStore} from './store';
import apiStore from './api/store';
import {observer, Provider} from 'mobx-react';
import TopAppBar from "./components/common/TopAppBar";
import logo from './modules/5e/images/dnd_logo_wide.png';
import {createGenerateClassName, jssPreset, ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles';
import { StylesProvider } from '@material-ui/core/styles';
import theme from './theme';
import {ThemeProvider} from 'styled-components';
import {createBrowserHistory} from 'history';
import {JssProvider} from "react-jss";
import {create} from "jss";
import {Router, Route} from 'react-router-dom';
import CookieConsent from "react-cookie-consent";

import { library } from '@fortawesome/fontawesome-svg-core';
import { fab } from '@fortawesome/free-brands-svg-icons';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { far } from '@fortawesome/free-regular-svg-icons';

import './App.css';
import ReactGA from 'react-ga';
import {About, AccountVerified, Privacy, Terms} from "./components/pages";
import NewsDialogs from "./components/common/NewsDialogs";
import {DnD5eScreen} from "./screens";

library.add(fas, fab, far);

const store = new AppStore();

const navigationStore = new NavigationStore();


const history = createBrowserHistory();
history.listen(location => {
	ReactGA.set({ page: location.pathname });
	ReactGA.pageview(location.pathname)
});

const generateClassName = createGenerateClassName();
const jss = create({
  ...jssPreset(),
  // Define a custom insertion for injecting the JSS styles in the DOM
  insertionPoint: document.getElementById("jss-insertion-point")
});

const stores = {
  navigationStore,
  store,
  apiStore,
};


const Cookie = () => (
  <CookieConsent
    style={{ background: "#333", opacity: "0.95"}}
    buttonStyle={{ fontWeight: "bold", background: "#DF9E00", padding: "12px", color: "black"}}
    >
    We use cookies to offer you a better experience. By continuing to use this website, you consent to the use of cookies in accordance with our Cookie Policy.
  </CookieConsent>
)



@observer
class App extends Component {
  render() {
    return (
    <StylesProvider injectFirst>
      <JssProvider jss={jss} generateClassName={generateClassName}>
        <MuiThemeProvider theme={theme}>
          <ThemeProvider theme={theme}>
            <Provider {...stores}>
              <Router history={history}>
                <div className="container">
                  <TopAppBar logoSrc={ logo } />
                  <NewsDialogs/>
                  <Route exact path="/" component={DnD5eScreen} />
                  <Route path="/pages/about" component={About} />
                  <Route path="/pages/legal/terms" component={Terms} />
                  <Route path="/pages/legal/privacy" component={Privacy} />
                  <Route path="/pages/account/verified" component={AccountVerified} />
                  <Cookie/>
                </div>
              </Router>
            </Provider>
          </ThemeProvider>
        </MuiThemeProvider>
      </JssProvider>
    </StylesProvider>
    );
  }
}

export default App;
