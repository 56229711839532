import {observer} from "mobx-react";
import {Draggable} from "react-beautiful-dnd";
import React from "react";
import TrackerItem from "./TrackerItem";


const TrackerItemList = observer(({provided, snapshot, items}) => {
  return items.map((item, index) => (
    <Draggable key={item.getKeyId()} draggableId={item.getKeyId()} index={index}>
      {(provided, snapshot) => (
        <div
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
        >
          <TrackerItem item={item} snapshot={snapshot} provided={provided} />
        </div>
      )}
    </Draggable>
  ))
});

export default TrackerItemList;
