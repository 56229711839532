import React, {Fragment} from "react";
import PropTypes from "prop-types";
import {inject, observer} from "mobx-react";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import {MainGridContainer} from "../../../components/styled/layout";
import styled from "styled-components";


import { Grid, Button, Typography, ExpansionPanel, ExpansionPanelSummary } from '@material-ui/core';


const PickButton = styled(Button)`
  margin: 5px;
`;

const Header = styled(Typography)`
  background: ${props => props.theme.palette.secondary.main};
  color: ${props => props.theme.palette.secondary.contrastText};
  padding: ${props => props.theme.spacing(1)}px;
  text-align: center;
`;

const LevelButtonsList = styled.div`
  margin-bottom: ${props => props.theme.spacing(1)}px;
`;

const Detail = styled(Typography)`
  width: 100%;
  margin-bottom: ${props => props.theme.spacing(1)}px;
`;

const DetailsBlock = styled.div`
  padding: ${props => props.theme.spacing(2)}px;
`;

const levels = [...Array(10).keys()];
const letters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'.split('');


@inject('store') @observer
class LevelButton extends React.Component {
  changeLevel = () => {
    this.props.store.screen.spells.currentLevel = this.props.level;
    this.props.store.screen.spells.loadFilteredSpells();
  };

  getVariant() {
    return this.props.store.screen.spells.currentLevel === this.props.level ? 'contained': 'outlined'
  }

  getColor() {
    return this.props.store.screen.spells.currentLevel === this.props.level ? 'secondary': 'default'
  }

  render() {
    const { level, label=null } = this.props;
    return (
      <PickButton variant={ this.getVariant() }
              color={ this.getColor() }
              data-level={ level }
              onClick={ () => this.changeLevel() } >
        {label ? label : level }
      </PickButton>
    )
  }
}


@inject('store') @observer
class LetterButton extends React.Component {

  changeLetterTo(letter) {
    this.props.store.screen.spells.currentLetter = letter;
    this.props.store.screen.spells.loadFilteredSpells();
  }

  getVariant() {
    return this.props.store.screen.spells.currentLetter === this.props.letter ? 'contained': 'outlined'
  }

  getColor() {
    return this.props.store.screen.spells.currentLetter === this.props.letter ? 'secondary': 'default'
  }

  render() {
    const { letter } = this.props;

    return (
      <PickButton
        variant={ this.getVariant() }
        color={ this.getColor() }
        data-letter={ letter }
        onClick={ () => { this.changeLetterTo(letter) }}>
        {this.props.label ? this.props.label : letter }
      </PickButton>
    )
  }
}


class SpellPanel extends React.Component {

  render() {
    const { spellDetail } = this.props;

    return (
      <ExpansionPanel>
        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>{ spellDetail.name }</Typography>
        </ExpansionPanelSummary>
        <DetailsBlock>
          <Detail>
            <em>
              { spellDetail.level } { spellDetail.school } spell
              ({ spellDetail.class })
            </em>
          </Detail>
          <Detail>
            <strong>Casting time:</strong> { spellDetail.casting_time }
          </Detail>
          <Detail>
            <strong>Range:</strong> { spellDetail.range }
          </Detail>
          <Detail>
            <strong>Components:</strong> { spellDetail.components } <em>{ spellDetail.material }</em>
          </Detail>
          <Detail>
            <strong>Duration:</strong> { spellDetail.duration }
          </Detail>
          <Detail>
            <strong>Concentration:</strong> { spellDetail.concentration }
          </Detail>
          <Detail>
            <strong>Ritual:</strong> { spellDetail.ritual }
          </Detail>
          <Typography dangerouslySetInnerHTML={{__html: spellDetail.desc }} />
          <Typography dangerouslySetInnerHTML={{__html: spellDetail.higher_level }} />
          <Detail>
            <strong>Source:</strong> { spellDetail.page }
          </Detail>
        </DetailsBlock>
      </ExpansionPanel>
    )
  }
}

SpellPanel.propTypes = {
  spellDetail: PropTypes.object
};


@inject('store') @observer
class SpellsList extends React.Component {
  render(){
    if (!(this.props.store.screen.spells.currentLetter !== null || this.props.store.screen.spells.currentLevel !== null)) return null;
    return (
      <Fragment>
        <Header>
          Spells
          {this.props.store.screen.spells.currentLevel !== null && ` on level ${this.props.store.screen.spells.currentLevel}`}
          {this.props.store.screen.spells.currentLetter && ` starting with ${this.props.store.screen.spells.currentLetter} letter`}
        </Header>
        {this.props.store.screen.spells.filtered.map(function(spell, index){
          return <SpellPanel key={`spells-list-entry-${index}`} spellDetail={ spell } />
        })}
      </Fragment>
    );
  };
}


@inject('store') @observer
export class Spells extends React.Component {

  componentDidMount() {
    this.props.store.screen.spells.loadFilteredSpells();
  }

  renderLevelButtons = () => {
    return levels.map(function(level, index){
            return  <LevelButton key={`spell-level-button-${index}`} level={ level }/>
    })
  };

  renderLetterButtons = () => {
    return letters.map(function(letter, index){
      return <LetterButton key={`spell-letter-button-${index}`} letter={ letter } />
    })
  };

  render() {
    return (
      <MainGridContainer container spacing={1}>
        <Grid item xs={12} sm={6} md={5} lg={4}>
          <Header>
            Spell level
          </Header>
          <LevelButtonsList>
            <LevelButton key={`spell-level-100`} level={ null } label='Any'/>
            { this.renderLevelButtons() }
          </LevelButtonsList>

          <Header>
            Name first letter
          </Header>
          <LetterButton letter={ null } label='Any' />
          { this.renderLetterButtons() }
        </Grid>
        <Grid item xs={12} sm={6} md={7} lg={8}>
          <SpellsList/>
        </Grid>
      </MainGridContainer>
    )
  }
}
