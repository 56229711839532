import React from "react";

import LabelBottomNavigation from "../../components/common/LabelBottomNavigation";

import {inject, observer, Provider} from 'mobx-react';

import {TabsStore} from './stores';
import SwipeableViews from 'react-swipeable-views';
import Notes from "../../modules/Notes/Notes";
import styled from "styled-components";
import { CombatTracker, Spells, Tables } from "../../modules/5e";



const tabsStore = new TabsStore();

const Screen = styled.div`
  margin-top: ${props => props.theme.spacing(6)}px;
  padding: 
    ${props => props.theme.spacing(1)}px
    ${props => props.theme.spacing(1)}px
    ${props => props.theme.spacing(1)}px;
`;

const BottomNavSwipeableViews = styled(SwipeableViews)`
  margin-bottom: 80px;
`;


@inject('tabsStore', 'store') @observer
class DnD5eScreenPages extends React.Component {

  handleChangeIndex = index => {
    this.props.tabsStore.setTab(index);
  };

  getCurrentTab(){
    return parseInt(this.props.tabsStore.currentTab, 10);
  };

  render() {
    return (
      <Screen>
        <BottomNavSwipeableViews
          // enableMouseEvents={this.props.store.screen.canSwipe}
          // FIXME: This is still not working as expected and there seems to be no solution
          enableMouseEvents={false}
          disableLazyLoading={true}
          // disabled={!this.props.store.screen.canSwipe}
          disabled={true}
          resistance
          index={this.getCurrentTab()}
          onChangeIndex={this.handleChangeIndex}>
          <Tables/>
          <CombatTracker
            items={this.props.store.screen.tracker.items}
            selectedItem={this.props.store.screen.tracker.selected}
          />
          {/*<Syrinscape />*/}
          {/*<Spotify />*/}
          <Spells />
          <Notes />
        </BottomNavSwipeableViews>

        <Provider tabsStore={ this.props.tabsStore }>
          <LabelBottomNavigation/>
        </Provider>
      </Screen>
    )
  }
}


@observer
export class DnD5eScreen extends React.Component {
  render(){
    return (
      <Provider tabsStore={tabsStore}>
        <DnD5eScreenPages />
      </Provider>
    )
  }
}
