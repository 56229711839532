import React from "react";
import CombatTrackerBar from "../../../components/common/tracker/TrackerBar";
import CombatTrackerNavigation from "../../../components/common/tracker/Navigation";
import { CombatTrackerDetail } from "./CombatTrackerDetail";
import DamageControl from "../../../components/common/tracker/DamageControl";
import HintsCard from "../../../components/common/HintsCard";
import {MainGridContainer} from "../../../components/styled/layout";
import { Grid } from '@material-ui/core';
import {TrackerCharacterGroup} from "./stores";


const hints = [
  {
    title: 'Deal damage directly',
    text: 'You can Ctrl+click (⌘+Click on MacOS) any NPC minion HP box in order to instantly deal damage value from the Damage Control panel.'
  },
  {
    title: 'Deal only half damage',
    text: 'Successful Reflex Saving Throw for NPCs? You can Ctrl+alt+click (⌘+alt+click on MacOS) to deal directly half damage instead of full. It will be rounded down.'
  },
  {
    title: 'Select minions for damage',
    text: 'Use Shift+click on creature minions HP box to select it (marked with yellow background). Hitting "Apply to Selected" will change HP for the selected NPCs.'
  },
  {
    title: 'No need to click button',
    text: 'Once you have minions selected on your tracker, you can input damage value into Damage Taken and hit Enter key to instantly apply damage to all selected.'
  }
];



interface CombatTrackerProps {
  selectedItem: TrackerCharacterGroup,
  items: TrackerCharacterGroup[];
}


export const CombatTracker: React.FC<CombatTrackerProps> = ({items, selectedItem}: CombatTrackerProps) => {
  return (
    <MainGridContainer container spacing={1}>
    <Grid item xs={12}>
      <CombatTrackerNavigation/>
      <CombatTrackerBar items={items}/>
      <Grid container spacing={1}>
        <Grid item xs={12} sm={4} md={3} lg={2}>
          <DamageControl />
          <HintsCard hints={hints} autoplay={true}/>
        </Grid>
        <Grid item xs={12} sm={8} md={9} lg={10}>
          <CombatTrackerDetail selectedItem={selectedItem}/>
        </Grid>
      </Grid>
    </Grid>
    </MainGridContainer>
  )
}
