import { withStyles, FormControlLabel, Switch, Button, Drawer } from "@material-ui/core";
import {inject, observer} from "mobx-react";
import {Component} from "react";
import DeleteIcon from "@material-ui/icons/Delete";
import { PlayerCharactersList } from "../../../modules/5e/CombatTracker";
import { NonPlayerCharactersList } from "../../../modules/5e/CombatTracker";
import ConfirmationDialog from "../ConfirmationDialog";
import React from "react";
import styled from "styled-components";
import GroupIcon from "@material-ui/icons/Group";
import SkipNext from "@material-ui/icons/SkipNext";
import ReactGA from "react-ga";

const styles = (theme) => ({
  buttonIcon: {
    fontSize: 20,
    marginRight: theme.spacing(1),
  }
});


const SpacyButton = styled(Button)`
  margin-right: ${props => props.theme.spacing(1)}px;
`;

const ActionsNavigation = styled.div`
  display: flex;
  margin-bottom: ${props => props.theme.spacing(1)}px;
`;

const ActionsLeft = styled.div`
  flex-grow: 1;
  text-align: left;
`;

const ActionsRight = styled.div`
  text-align: right;
`;


@withStyles(styles, { withTheme: true})
@inject('store') @observer
class CombatTrackerNavigation extends Component {

  state = {
      confirmDialogOpened: false,
      confirmDialogContent: null,
      confirmDialogCallback: null,
      drawerOpened: false,
      currentAddList: null,
    };

  confirm(event, action, confirmed=false, content){
    if(event){
      event.stopPropagation();
      if(!confirmed){
        this.setState({
          confirmDialogCallback: (result) => this.confirm(null, () => action(), result),
          confirmDialogContent: content,
          confirmDialogOpened: true,
        });
        return;
      }
    }

    if(confirmed){
      action();
    }

    this.setState({
      confirmDialogOpened: false,
    });
  }

  clearTracker = (e) => {
    this.confirm(e, () => {
      ReactGA.event({
        category: 'Combat Tracker',
        action: 'Cleared Tracker'
      });
      this.props.store.screen.tracker.clearTracker();
      this.props.store.screen.tracker.selected = null;
    }, false, "Are you sure you want to clear tracker?")
  };

  toggleDrawer = () => {
    this.setState({
      drawerOpened: !this.state.drawerOpened,
    })
  };

  toggleAutoSort = () => {
    this.props.store.screen.toggleAutoSort();
  };

  nextInit = () => {
    ReactGA.event({
      category: 'Combat Tracker',
      action: 'Clicked Next Turn Button'
    });
    this.props.store.screen.tracker.nextInit();
  };

  resetRound = () => {
    this.props.store.screen.tracker.resetRound();
  };

  toggleNPCDrawer = () => {
    ReactGA.event({
      category: 'Combat Tracker',
      action: 'Opened NPC Drawer'
    });
    this.setState({ currentAddList: 'NPC'});
    this.toggleDrawer();
  };

  togglePCDrawer = () => {
    ReactGA.event({
      category: 'Combat Tracker',
      action: 'Opened PC Drawer'
    });
    this.setState({ currentAddList: 'PC'});
    this.toggleDrawer();
  };

  render() {
    const { classes } = this.props;
    let autoSort = this.props.store.screen.setting_autoSort;

    return (
      <div>
        <ActionsNavigation>
          <ActionsLeft>
            <SpacyButton variant="contained" color="secondary" onClick={this.nextInit}>
              <SkipNext/>
            </SpacyButton>
          </ActionsLeft>
          <ActionsRight>
            <FormControlLabel
              control={
                <Switch
                  checked={autoSort}
                  onChange={this.toggleAutoSort}
                />
              }
              label="Automatically sort items by Initiative"
            />
            <SpacyButton variant="outlined" onClick={this.resetRound}>
              Reset round
            </SpacyButton>
            <SpacyButton variant="outlined" onClick={this.clearTracker}>
              <DeleteIcon className={classes.buttonIcon}/>
              Clear tracker
            </SpacyButton>&nbsp;
            <SpacyButton variant="contained" color="secondary" onClick={this.togglePCDrawer}>
              <GroupIcon className={classes.buttonIcon}/>
              Characters
            </SpacyButton>&nbsp;
            <Button variant="contained" color="secondary" onClick={this.toggleNPCDrawer}>
              <GroupIcon className={classes.buttonIcon}/>
              NPCs
            </Button>
          </ActionsRight>
        </ActionsNavigation>

        <Drawer open={this.state.drawerOpened} onClose={this.toggleDrawer}
                anchor="right">
          <div style={{ padding: 10, minWidth: 400 }}>
            {this.state.currentAddList === 'PC' && (
              <PlayerCharactersList screen={this.props.store.screen}/>
            )}
            {this.state.currentAddList === 'NPC' && (
              <NonPlayerCharactersList screen={this.props.store.screen}/>
            )}
          </div>
        </Drawer>

        <ConfirmationDialog open={this.state.confirmDialogOpened}
                            onClose={this.state.confirmDialogCallback}
                            content={this.state.confirmDialogContent}/>
      </div>
    )
  }
}

export default CombatTrackerNavigation;
