import React from 'react';
import ScreenTable from "../../../components/common/ScreenTable";


const dcData = [
  ['Very Easy', 5],
  ['Easy', 10],
  ['Medium', 15],
  ['Hard', 20],
  ['Very Hard', 25],
  ['Nearly Impossible', 30]
];

const TypicalDCTable = (props) => {
  return <ScreenTable
    title="Typical DCs"
    body={dcData}
  />
};

export default TypicalDCTable;
