import React from 'react';
import Notifications from "@material-ui/icons/Notifications";
import Settings from "@material-ui/icons/Settings";
import PermIdentity from "@material-ui/icons/PermIdentity";
import ScreenShare from "@material-ui/icons/ScreenShare";
import styled from 'styled-components';
import UndecoratedLink from "./UndecoratedLink";


import { List, ListItem, ListItemIcon, ListItemText, Divider } from '@material-ui/core';


const ListContainer = styled.div`
  width: 300px;
  background-color: ${props => props.theme.palette.background.paper};
`;


const SidebarNavigationList = () => (
    <ListContainer>
      <List component="nav">
        <UndecoratedLink to="/pages/about">
          <ListItem button>
            <ListItemIcon>
              <Notifications />
            </ListItemIcon>
            <ListItemText primary="What's new" >
            </ListItemText>
          </ListItem>
        </UndecoratedLink>
        <ListItem button>
          <ListItemIcon>
            <Settings />
          </ListItemIcon>
          <ListItemText primary="Ruleset Settings" secondary="(soon)" />
        </ListItem>
      </List>
      <Divider />
      <List component="nav">
        <ListItem button>
          <ListItemIcon>
            <PermIdentity />
          </ListItemIcon>
          <ListItemText primary="Characters" secondary="(soon)" />
        </ListItem>

        <ListItem button>
          <ListItemIcon>
            <PermIdentity />
          </ListItemIcon>
          <ListItemText primary="NPCs" secondary="(soon)" />
        </ListItem>

        <ListItem button>
          <ListItemIcon>
            <PermIdentity />
          </ListItemIcon>
          <ListItemText primary="Monsters" secondary="(soon)" />
        </ListItem>

        <Divider/>

        <ListItem button>
          <ListItemIcon>
            <ScreenShare />
          </ListItemIcon>
          <ListItemText primary="Switch ruleset" secondary="(soon)" />
        </ListItem>

      </List>
    </ListContainer>
);

export default SidebarNavigationList;
