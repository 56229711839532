import {Fab} from "@material-ui/core";
import {inject, observer} from "mobx-react";
import React, {Fragment} from "react";
import { SyncManager } from ".";
import { SyncConflictDialog } from ".";
import {AppBarFontAwesomeIcon, LastSyncInfo, SyncButtonWrapper, SyncProgress} from "./styled";
import moment from 'moment-timezone';

const SyncButton = ({title, icon, ...other}) => (
  <Fab size="small" variant="extended" color="primary" title={title} {...other}>
    <AppBarFontAwesomeIcon icon={icon} size="1x"/>
  </Fab>
)




@inject('store') @observer
class Sync extends React.Component<any, any> {
  state = {
      loading: false,
    }

  constructor(props) {
    super(props);
    setInterval(
      () => {
        this.forceUpdate()
      },
      5000)
  }

  getLastSyncFriendly = () => {
    if (! this.props.store.clientLastSync) {
      return "Never"
    }
    return moment(this.props.store.clientLastSync).fromNow();
  }

  getLastSyncFormatted = () => {
    if (! this.props.store.clientLastSync) {
      return "Never"
    }
    return moment(this.props.store.clientLastSync).format('dddd, D MMMM YYYY, H:mm:ss')
  }

  sync = () => {
    this.setState({loading: true})
    const manager = new SyncManager(this.props.store)
    manager.sync().then(() => {
      this.setState({loading: false})
    })
  };


  render() {
    return (
      <Fragment>
        <LastSyncInfo variant="body2" title={`Last sync on this device: ${this.getLastSyncFormatted()}`}>
          Last sync on this device: {this.getLastSyncFriendly()}
        </LastSyncInfo>
        <SyncButtonWrapper>
          <SyncButton title="Sync data" icon="sync" onClick={this.sync}/>
          {this.state.loading && <SyncProgress color="secondary" size={36}/>}
        </SyncButtonWrapper>
        <SyncConflictDialog/>
      </Fragment>
    )
  }
}

export default Sync;
