import React, {Component} from 'react';
import {inject, observer} from "mobx-react";

import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
} from '@material-ui/core';


@inject('store') @observer
class SignUpVerificationDialog extends Component {

  handleClose = () => {
    this.props.store.signUpVerificationOpen = false;
  }

  render() {
    return (
        <Dialog
          open={this.props.store.signUpVerificationOpen}
          onClose={this.handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">Almost there...</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              In order to complete your account creation process we have sent
              verification message to your e-mail address. Go find it in your
              inbox and click the link there to activate your account.
              <br/>
              Only then you will be able to sign in.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleClose} color="primary" autoFocus>
              OK
            </Button>
          </DialogActions>
        </Dialog>
    );
  }
}

export default SignUpVerificationDialog;