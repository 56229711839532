import {API_URL} from "../config";
import apiStore from './store';

const requests = {
  get: (url, params={}) => {
    return apiStore.getAxiosInstance().get(`${API_URL}/api${url}`, {params: params})
      .then(response => response.data)
  },

  post: (url, data={}) => {
    return apiStore.getAxiosInstance().post(`${API_URL}/api${url}`, data)
      .then(response => response.data)
  },

  put: (url, data={}) => {
    return apiStore.getAxiosInstance().put(`${API_URL}/api${url}`, data)
      .then(response => response.data)
  }
};


const Auth = {
  signIn: ({email, password}) =>
    requests.post('/v1/auth/login/', {email, password})
      .then((result) => {
        if (result){
          apiStore.setToken(result.key);
        }
      })
      .catch(err => {
        throw err.response;
      }),
  signUp: ({username, email, password1, password2}) =>
    requests.post(`/v1/auth/registration/`, {username, email, password1, password2})
      .catch(err => {
        throw err.response;
      }),
  signout: () =>
    requests.post("/v1/auth/logout/", {})
      .then(data => {apiStore.signOut(); return data}),
  isSignedIn: () => new Promise((resolve, reject) => {
    if(!apiStore.signed) {
      return resolve(false)
    }
    requests.post('/v1/auth/token-verify/')
    .then(() => resolve(true))
    .catch((err) => {
      if(err.response.status === 401){
        apiStore.setToken(null); // clear token as it is no longer valid
        return resolve(false);
      }
    })
  })
};


const UserData = {
  upload: (json) =>
    requests.put(`/v1/users/`, {"front_settings": json})
      .catch(err => {throw err.response.data;}),
  download: () => {
    return requests.get('/v1/users/')
  }
};


export const api = {
  get: requests.get,
  post: requests.post,
  Auth,
  UserData,
  apiStore: apiStore
};
