import {action, observable} from "mobx";
import {loadState, setState} from "../../../../utils/localStorage";
import {removeFromArray} from "../../../../utils/utils";
import {Character} from "./Characters";


class TrackerCharacter {
  @observable root;
  @observable id!: string | number;

  @observable hp: number = 0;
  @observable hpMax: number = 0;
  @observable hpDelta: number = 0;
  @observable ac: number = 0;
  @observable label: string = '';
  @observable notes: string = '';
  @observable type: string = '';

  constructor(root: any, id: string | number){
    this.root = root
    this.id = id
  }

  @action.bound
  changeHPDelta(difference){
    this.hpDelta = this.hpDelta + difference;

  }

  @action.bound
  applyHPDelta(){
    this.hp = this.hp - this.hpDelta;
    this.hpDelta = 0;
    this.root.saveData();
  }

  @action.bound
  applyDamage(amount) {
    this.hp -= parseInt(amount);
    if (this.hp < 0) {
      this.hp = 0;
    }
    this.root.saveData()
  }
}


export class TrackerCreatureCopy extends TrackerCharacter {
  @observable selected = false;

  @action.bound
  toggleSelect() {
    this.selected = !this.selected;
    this.root.saveData();
  }
}


export class TrackerCharacterGroup {
  @observable root;
  @observable id;

  @observable character: Character;
  @observable copies: TrackerCreatureCopy[] = [];
  @observable hp: number = 0;

  @observable multiple = 1;
  @observable id_prefix = '';

  @observable currentId = 1;
  @observable type = null;

  constructor(root, id, character){
    this.root = root;
    this.id = id;
    this.character = character;
    // reaction(() => toJS(this.character), () => this.updateHPs());
  }

  @action.bound
  updateHPs(){
    this.hp = this.copies[0].hp;
  }

  @action.bound
  createCopies(data){
    if(data) {
      data.forEach((copy) => {
        this.appendCopy(this.createCopy({
          hp: copy.hp,
          hpMax: copy.hpMax,
          hpDelta: copy.hpDelta,
          ac: copy.ac,
          notes: copy.notes,
          label: copy.label,
          selected: copy.selected,
        }), false);
      });
    }
  }

  @action.bound
  createFreshCopies(number){
    for(var i=0; i < number; i++){
      this.appendCopy((this.createFreshCopy()));
    }
  }

  @action.bound
  createFreshCopy(){
    const c = this.character;
    return this.createCopy({
      hp: c.hp,
      hpMax: c.hpMax,
      hpDelta: c.hpDelta,
      ac: c.ac,
    })
  }

  @action.bound
  createCopy({
    hp=0,
    hpMax=0,
    hpDelta=0,
    ac=0,
    label='',
    notes='',
    selected=false,
             }){
    const copy = new TrackerCreatureCopy(this, this.currentId);
    this.currentId++;

    copy.hp = hp;
    copy.hpMax = hpMax;
    copy.hpDelta = hpDelta;
    copy.ac = ac;
    copy.label = label;
    copy.notes = notes;
    copy.selected = selected;

    if(copy.selected) {
      // populate copy selection to the damage control
      this.root.root.damageControl.addSelected(copy);
    }

    return copy;
  }

  getKeyId(){
    return `${this.id_prefix}tracker-item-key-${this.id}`;
  }

  isMultiple(){
    return this.multiple && this.multiple > 1;
  }

  saveData(){
    this.root.saveData()
  }

  @action.bound
  appendCopy(copy, save=true){
    this.copies.push(copy);
    if(save)
      this.saveData();
  }
}



export class TrackerStore {
  state_key = 'TrackerItems';
  id_prefix = '';

  @observable root;
  @observable current: TrackerCharacterGroup | undefined = undefined;
  @observable current_index: number = 0;
  @observable currentId: number = 0;

  @observable selected = null;
  @observable items: TrackerCharacterGroup[];

  constructor(root){
    this.root = root;
    this.items = observable([]);
    this.fetchData();
  }


  @action.bound
  createCharacter(character, createMultiples= true) {
    const cg = new TrackerCharacterGroup(this, this.currentId, character);
    this.currentId++;

    cg.character = character;
    cg.character.hpMax = character.hp;
    cg.multiple = character.multiple;
    cg.type = character.type;

    if(createMultiples){
      cg.createFreshCopies(character.multiple);
      cg.updateHPs();
    }

    return cg;
  }

  @action.bound
  fetchData(){  // todo: rename to loadData
    const data = loadState(this.state_key);
    if(data && data.items && data.items.length){
      return this.loadData(data.items)
    }
    return false;
  }

  dumpData() {
    return this.items.map((item) => ({
        name: item.character.name,
        init: item.character.init,
        init_mod: item.character.init_mod,
        hp: item.character.hp,
        hpMax: item.character.hpMax,
        hpDelta: item.character.hpDelta,
        ac: item.character.ac,
        multiple: item.multiple,
        id_prefix: item.id_prefix,
        type: item.type,
        copies: item.copies.map((copy) => ({
            id: copy.id,
            hp: copy.hp,
            hpMax: copy.hpMax,
            hpDelta: copy.hpDelta,
            ac: copy.ac,
            label: copy.label,
            notes: copy.notes,
            selected: copy.selected
        }))
    }));
  }

  saveData(){
    setState(this.state_key, {items: this.dumpData() });
  }

  loadData(data) {
    if(data.length){
      this.items = observable([]);

      data.map((item) => {
        const character = this.createCharacter({ // TODO: Move this logic from here
          name: item.name,
          init: parseInt(item.init),
          init_mod: parseInt(item.init_mod),
          hp: parseInt(item.hp),
          hpMax: parseInt(item.hpMax),
          hpDelta: parseInt(item.hpDelta),
          ac: parseInt(item.ac),
          multiple: parseInt(item.multiple),
          id_prefix: item.id_prefix,
          type: item.type,
        }, false);

        character.copies = observable([]);
        item.copies.forEach((copy) => {
          character.appendCopy(character.createCopy({
            hp: parseInt(copy.hp),
            hpMax: parseInt(copy.hpMax),
            hpDelta: parseInt(copy.hpDelta),
            ac: parseInt(copy.ac),
            label: copy.label,
            notes: copy.notes,
            selected: copy.selected,
          }), false)
        });
        return this.appendItem(character, false)
      });
      return true;
    }
    return false;
  }


  @action.bound
  sortItems() {
    const items = this.items;
    this.items = observable(items.slice().sort(function(a, b) {
      return b.character.init - a.character.init;
    }));
  };

  @action.bound
  reorder(startIndex, endIndex){
    const result = Array.from(this.items);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    this.items = observable([...result]);
  };


  @action.bound
  appendItem(character, save= true){
    // push instead
    this.items.push(character);
    if(this.root.getAutoSort()){
      this.sortItems();
    }
    if (save) {
      this.saveData();
    }
  }

  getItem(item_id){
    return this.items.find(item => item.id === item_id);
  }


  @action.bound
  removeItem(id) {
    this.items = observable(removeFromArray(this.items, "id", id));
    this.saveData();
    this.current_index = 0;
    this.current = undefined;
  }

  @action.bound
  clearTracker(){
    this.items = observable([]);
    this.saveData();
  }

  @action.bound
  setInit(item){
    let new_index  = 0;
    let new_current = undefined;
    this.items.forEach((tracker_item, index) => {
      if (tracker_item === item){
        new_index = index;
        new_current = item;
      }
    });
    this.current_index = new_index;
    this.current = new_current;
  }

  @action.bound
  nextInit(){
    if (!this.current){
      this.current_index = 0;
      this.current = this.items[0];
    }
    else {
      this.current_index = this.current_index + 1;
      this.current = this.items[(this.current_index) % this.items.length];
    }
  }

  @action.bound
  resetRound(){
    this.current_index = 0;
    this.current = this.items[0];
  }

}
