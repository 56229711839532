import styled from "styled-components";
import {TableCell, TableRow} from '@material-ui/core';

export const ScrollableXSpacedBottom = styled.div`
  overflow-x: auto;
  margin-bottom: ${props => props.theme.spacing(1)}px; 
`;

export const DataRow = styled(TableRow)`
  height: ${props => props.theme.typography.fontSize * 2.2}px;
`;

export const DataCell = styled(TableCell)`
  height: ${props => props.theme.typography.fontSize * 2.2}px;
  padding: ${props => props.theme.spacing(0.5)}px ${props => props.theme.spacing(2)}px;
`;