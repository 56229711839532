import React from 'react';
import PanelsList from "../../../components/common/PanelsList";


const SchoolsOfMagic = () => {
  return (
      <PanelsList
        storeName='schoolsOfMagic'
        title='The Schools of Magic'
        id='id_schools_of_magic'
      />
  )
};

export default SchoolsOfMagic;
