import {api} from "../../../api/agent";
import {AppStore} from "../../../store";
import moment from 'moment-timezone';


class SyncManager {
  store: AppStore

  constructor(store: AppStore) {
    this.store = store;
  }

  isEmpty(obj: Object) {
    if(obj === null) return true;
    return Object.keys(obj).length === 0 && obj.constructor === Object
  }

  hasLocalData() {
    const data = this.store.dumpData()
    return !this.isEmpty(data)
  }

  sync() {
    return this.download()
      .then(response => {
        const remoteData = response.front_settings
        const hasRemoteData = !(this.isEmpty(remoteData))
        const hasLocalData = this.hasLocalData()

        const serverLastSync = moment(response.last_sync)
        const clientLastSync = moment(this.store.clientLastSync)

        // TODO: Remove debug info
        // console.log(
        //   "realServerLastSync: ", response.last_sync,
        //   "realClientLastSync: ", this.store.clientLastSync,
        //   "clientLastSyncToDate", moment(moment(response.last_sync).toDate()),
        //   "serverLastSync:", serverLastSync,
        //   "clientLastSync: ", clientLastSync,
        //   "hasLocalData: ", hasLocalData,
        //   "hasRemoteData: ", hasRemoteData,
        //   "sameTime? ", serverLastSync.isSame(clientLastSync)
        // )

        if (!response.last_sync) {
          // server does not have last sync set yet (new user), so we will just send data
          return this.upload();
        }

        if (!hasRemoteData) {
          // server data is clean, we can safely upload information
          return this.upload()
        }

        if (!hasLocalData) {
          // this client is clear and we can use server data in it
          this.updateClient(response)
          return
        }

        if (serverLastSync.isSame(clientLastSync)) {
          // both client and server have data, but last sync is the same
          // we can safely update server information
          return this.upload()
        }

        // both may have changed data and times are different, not sure, which one to pick
        // display conflict message and let the user decide
        this.conflict(serverLastSync, response)
      })

  }

  conflict(serverLastSync: any, response: Object) {
    this.store.serverLastSync = serverLastSync
    this.store.remoteData = response
    this.store.conflictDialogOpen = true;
  }

  resolveConflictPullData() {
    this.updateClient(this.store.remoteData)
    this.upload()
    this.cleanupConflict()
  }

  resolveConflictPushData() {
    this.upload()
      .then(() => {
        this.cleanupConflict()
      })
  }

  cleanupConflict() {
    this.store.remoteData = undefined
    this.store.serverLastSync = undefined
    this.store.conflictDialogOpen = false
  }

  dumpData(){
    /*
    Dumps data from all data stores definied in this.dataStores
     */
    const data = {}
    this.store.screen.dataStores.forEach(store => {
      data[store] = this.store.screen[store].dumpData()
    })
    return data
  }

  loadData(data) {
    /*
    Loads all data to proper this.dataStores based on the proper keys
     */
    Object.keys(data).forEach(store => {
      if (this.store.screen.dataStores.includes(store)) {
        this.store.screen[store].loadData(data[store])
      }
    })
  }

  saveData(){
    this.store.screen.dataStores.forEach(store => {
      this.store.screen[store].saveData();
    })
  }

  updateClient(response: any) {
    const screenData = response.front_settings.dd5eScreen;
    this.loadData(screenData)
    this.saveData()
    this.store.clientLastSync = response.last_sync;
  }

  download() {
    return api.UserData.download()
  }

  upload() {
    return api.UserData.upload(
        {
          dd5eScreen: this.dumpData()
        })
        .then(response => {
          this.store.clientLastSync = moment(response.last_sync).toDate();
        });
  }

}

export default SyncManager
