import moment from 'moment-timezone';

import {action, computed, observable, reaction, runInAction} from 'mobx';
import {api} from "./api/agent";
import {loadState, setState} from "./utils/localStorage";
import {DD5eStore} from "./screens";


export class NavigationStore {
  @observable drawerStatus = false;

  @action.bound
  toggleDrawer(){
    this.drawerStatus = !this.drawerStatus;
  }
}

export class AppStore {

  key = "clientSettings";

  @observable signUpDialogOpen = false;
  @observable signInDialogOpen = false;
  @observable signUpVerificationOpen = false;

  @observable clientLastSync = undefined;
  @observable conflictDialogOpen = false;
  remoteData = undefined;
  @observable serverLastSync = undefined;

  @observable screen = new DD5eStore(this);

  @observable signedIn = undefined;

  constructor() {
    this.fetchData();

    reaction(
      () => this.data,
      () => this.saveData()
    )

    api.Auth.isSignedIn().then((value) => {
      runInAction(() => {
        this.signedIn = value
      })
    }).catch(err => {
      runInAction(() => this.signedIn = false)
    })
  }

  fetchData() {
    const data = loadState(this.key);
    if (!data) return;
    Object.assign(this, data);
  }

  @computed
  get data() {
    return {
      clientLastSync: this.clientLastSync
    }
  }

  @computed
  get lastSyncFriendly() {
    if (! this.clientLastSync) {
      return "Never"
    }
    return moment(this.clientLastSync).fromNow();
  }

  @computed
  get lastSyncFormatted() {
    if (! this.clientLastSync)
      return "Never"
    return moment(this.clientLastSync).format('dddd, D MMMM YYYY, H:mm:ss')
  }

  dumpData() {
    return this.data;
  }

  saveData(){
    const data = this.dumpData();
    setState(this.key, data);
  }

}
