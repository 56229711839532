import React, {Component, Fragment} from "react";
import {observer} from "mobx-react"
import AddIcon from "@material-ui/icons/Add";
import DeleteIcon from "@material-ui/icons/Delete";
import SendIcon from "@material-ui/icons/Send";
import ConfirmationDialog from "../../../components/common/ConfirmationDialog";
import {
  FullWideStat,
  LeftAlignedListItem,
  NarrowStat,
  RightAlignedActions,
  SpacyFab
} from "./styled/StyledCharactersList";


import {
  Checkbox,
  FormControlLabel,
  IconButton,
  List,
  ListItemSecondaryAction,
  ListItemText,
  Switch,
  Typography,
} from '@material-ui/core';
import {DD5eStore} from "../../../screens";


interface PlayerCharactersListProps {
  screen: DD5eStore  // TODO: Replace with generic interface
}


interface PlayerCharactersListState {
    selectedCharacters?: [],
    allSelected: boolean,
    confirmDialogOpened: boolean,
    confirmDialogContent?: string,
    confirmDialogCallback?(boolean): void
}


@observer
export class PlayerCharactersList extends Component<PlayerCharactersListProps, PlayerCharactersListState> {

  state: Readonly<PlayerCharactersListState> = {
    selectedCharacters: [],
    allSelected: false,
    confirmDialogOpened: false,
    confirmDialogContent: undefined,
    confirmDialogCallback: undefined,
  };

  addCharacter = () => {
    const character = this.props.screen.pcs.createCharacter({});
    this.props.screen.pcs.prependCharacter(character);
  };

  removeCharacter = id => {
    this.props.screen.pcs.removeItem(id);
  };

  addToTracker = () => {
    this.props.screen.pcs.addSelectedToTracker();
    this.setState({ allSelected: false });
  };

  confirm(event, action, confirmed=false, content){
    if(event){
      event.stopPropagation();
      if(!confirmed){
        this.setState({
          confirmDialogCallback: (result) => this.confirm(null, () => action(), result, content),
          confirmDialogContent: content,
          confirmDialogOpened: true,
        });
        return;
      }
    }

    if(confirmed){
      action();
    }

    this.setState({
      confirmDialogOpened: false,
    });
  }

  toggleAllSelected = () => {
    const is_selected = !this.state.allSelected;
    this.props.screen.pcs.setSelectAll(is_selected);
    this.setState({ allSelected: is_selected });
  };

  render() {
    let pcs = this.props.screen.pcs.items;

    return (
      <Fragment>
        <Typography variant="h5" gutterBottom>
          Player Characters List
        </Typography>

        <Fragment>
          <FormControlLabel
            control={
              <Switch
                checked={this.state.allSelected}
                onChange={this.toggleAllSelected}
              />
            }
            label="Select all"
          />

          <RightAlignedActions>
              <SpacyFab size='small' color="secondary" onClick={this.addCharacter}>
                <AddIcon/>
              </SpacyFab>
              <SpacyFab size='small' color="secondary" onClick={this.addToTracker}>
                <SendIcon />
              </SpacyFab>
          </RightAlignedActions>
        </Fragment>

        <List>
          {pcs.map((item) => (
            <LeftAlignedListItem
              key={`pc-${item.id}`}
              role={undefined}
              dense
              button
              onClick={() => item.toggleSelected() }
            >
              <Checkbox
                checked={item.selected}
                tabIndex={-1}
                disableRipple
              />
              <ListItemText>
                <FullWideStat item={item} propertyName='name' label='' placeholder='Character Name'/>
                <br/>
                <NarrowStat item={item} style={{marginLeft: 0}} propertyName='ac' label='AC' parser={parseInt}/>
                <NarrowStat item={item}
                            parser={parseInt}
                            propertyName='init'
                            label='Init'/>
              </ListItemText>
              <ListItemSecondaryAction>
                <IconButton aria-label="Remove"
                            onClick={((e) => this.confirm(
                              e,
                              () => this.removeCharacter(item.id),
                              false,
                              "Are you sure you want to remove Player Character?"
                            ))}>
                  <DeleteIcon />
                </IconButton>
              </ListItemSecondaryAction>
            </LeftAlignedListItem>
          ))}
        </List>

        <ConfirmationDialog open={this.state.confirmDialogOpened}
                            onClose={this.state.confirmDialogCallback}
                            content={this.state.confirmDialogContent}/>
      </Fragment>)
  }
}
