import React, {Component} from "react";
import {inject, observer} from "mobx-react";
import {api} from "../../../api/agent";
import {ErrorsList} from "../forms/styled";


import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  TextField,
  DialogActions,
  Button,
} from '@material-ui/core';
import {TextLink} from "../../styled/links";


@inject('store') @observer
class SignUpDialog extends Component {

  state = {
    username: '',
    email: '',
    password1: '',
    password2: '',
    errors: []
  };

  handleClose = () => {
    this.props.store.signUpDialogOpen = false;
  };

  signUp = (event) => {
    event.preventDefault();
    api.Auth.signUp(this.state)
      .then((response) => {
        this.props.store.signUpDialogOpen = false;
        this.props.store.signUpVerificationOpen = true;
      })
      .catch(err => {
        this.setState({errors: err})
      })
  };


  render() {
    const { classes, onClose, ...other } = this.props;

    return (
      <Dialog
        open={this.props.store.signUpDialogOpen}
        onClose={this.handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        {...other}
      >
        <DialogTitle id="alert-dialog-title">Sign Up</DialogTitle>
        <form onSubmit={this.signUp}>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              To sign up to Toady, please enter your email address below.
              By signing up you <TextLink to="/pages/legal/terms">accept the Terms & Conditions</TextLink>.
            </DialogContentText>
            <TextField
              autoFocus
              margin="dense"
              id="email-address"
              label="Email Address"
              type="email"
              fullWidth
              helperText={'email' in this.state.errors ? this.state.errors.email.join(" ") : null}
              error={'email' in this.state.errors}
              onChange={(event) => this.setState({email: event.target.value, username: event.target.value})}
            />
            <TextField
              margin="dense"
              id="password"
              label="Password"
              type="password"
              helperText={'password1' in this.state.errors ? this.state.errors.password1.join(" ") : null}
              fullWidth
              error={'password1' in this.state.errors}
              onChange={(event) => this.setState({password1: event.target.value})}
            />
            <TextField
              margin="dense"
              id="password_repeat"
              label="Repeat Password"
              type="password"
              fullWidth
              helperText={'password2' in this.state.errors ? this.state.errors.password2.join(" ") : null}
              error={'password2' in this.state.errors}
              onChange={(event) => this.setState({password2: event.target.value})}
            />
            {'non_field_errors' in this.state.errors && (
              <DialogContentText id="alert-dialog-description">
                <ErrorsList>
                  {this.state.errors.non_field_errors.map((error, index) => (
                    <li key={`signin-form-error-${index}`}>
                      {error}
                    </li>
                  ))}
                </ErrorsList>
              </DialogContentText>
            )}
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleClose} color="primary">
              Cancel
            </Button>
            <Button onClick={this.signUp} color="primary" type="submit">
              Sign Up
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    )
  }
}

export default SignUpDialog;
