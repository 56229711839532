import DeleteIcon from "@material-ui/icons/Delete";
import React from "react";
import ConfirmationButton from "./ConfirmationButton";

export default class DeleteConfirmationButton extends ConfirmationButton {
  render(){
    const {icon = <DeleteIcon/>, label = 'Delete', ...other} = this.props;
    return (<ConfirmationButton icon={icon} label={label} {...other}/>)
  }
}
