import React from 'react';
import MenuIcon from '@material-ui/icons/Menu';
import {inject, observer} from 'mobx-react';

import SidebarNavigationList from "./SidebarNavigationList";
import styled from "styled-components";
import UndecoratedLink from "./UndecoratedLink";
import AccountNavigation from "./AccountNavigation";


import { AppBar, Toolbar, Typography, IconButton, Drawer } from '@material-ui/core';


const DarkAppBar = styled(AppBar)`
  background-color: ${props => props.theme.palette.primary.main};
  height: ${props => props.theme.spacing(6)}px;
  min-height: ${props => props.theme.spacing(6)}px;
`;


const FullHeightToolbar = styled(Toolbar)`
  padding: 0;
  height: 100%;
  min-height: 100%;
`;


const ContrastUndecoratedLink = styled(UndecoratedLink)`
  color: ${props => props.theme.palette.primary.contrastText}; 
  flex-grow: 1
`;


const NavDrawerButton = styled(IconButton)`
  position: absolute;
  left: -5px;
  color: inherit;
`;


@inject('navigationStore') @observer
class TopAppBar extends React.Component {
  state = {
    auth: true,
    anchorEl: null,
    drawerMenu: true,
  };

  toggleDrawer = () => {
    this.props.navigationStore.toggleDrawer();
  };


  render() {
    const sideList = (
      <div>
        <SidebarNavigationList />
      </div>
    );

    return (
      <div>
        <DarkAppBar>
          <FullHeightToolbar>
            <ContrastUndecoratedLink to="/">
              <Typography variant="h6" color="inherit" style={{ paddingLeft: 40 }}>
                5e ruleset
              </Typography>
            </ContrastUndecoratedLink>

            <NavDrawerButton aria-label="Menu" onClick={this.toggleDrawer}>
              <MenuIcon />
            </NavDrawerButton>

            <AccountNavigation/>

          </FullHeightToolbar>
        </DarkAppBar>

        <Drawer open={this.props.navigationStore.drawerStatus} onClose={this.toggleDrawer}>
          <div
            tabIndex={0}
            role="button"
            onClick={this.toggleDrawer}
            onKeyDown={this.toggleDrawer}
          >
            {sideList}
          </div>
        </Drawer>
      </div>

    );
  }
}

export default TopAppBar;
