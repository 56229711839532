import React from 'react';
import ScreenTable from "../../../components/common/ScreenTable";


const travelPaceData = [
  ['Fast', '400 feet', '4 miles', '30 miles', '-5 penalty to passive Wisdom (Perception) scores'],
  ['Normal', '300 feet', '3 miles', '24 miles', '---'],
  ['Slow', '200 feet', '2 miles', '18 miles', 'Able to use Stealth']
];

const travelPaceHeader = ['Pace', 'Minute', 'Hour', 'Day', 'Effects'];

const TravelPace = (props) => {
  return <ScreenTable
    title="TravelPace"
    header={travelPaceHeader}
    body={travelPaceData}
  />
};

export default TravelPace;
