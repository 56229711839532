import React from "react";
import {inject, observer} from "mobx-react";
import styled from "styled-components";
import {DealButton, HealButton} from "../buttons/DamageButtons";
import DamageFormControl from "../DamageFormControl";
import ReactGA from "react-ga";


import { Typography, Input, Button } from '@material-ui/core';


const DamageControlContainer = styled.div`
  margin: ${props => props.theme.spacing(2)}px 0;
  border: 1px dashed ${props => props.theme.palette.primary.light};
  padding: ${props => props.theme.spacing(2)}px;
  text-align: center;
`;

const DamageLabel = styled(Typography)`
  margin-top: ${props => props.theme.spacing(2)}px;
`;

const ApplyButton = styled(Button)`
  margin-right: 4px;
`;

const ApplyContainer = styled.div`
  margin-top: 16px;
`;


@inject('store') @observer
class DamageControl extends React.Component {

  HEAL_CHANGE_BUTTONS = [-1, -5, -10];
  DEAL_CHANGE_BUTTONS = [1, 5, 10];


  updateHPChage = value => e => {
    ReactGA.event({
      category: 'Damage Control',
      action: 'Clicked on deal/heal button',
      value: value
    });
    this.props.store.screen.damageControl.HPChange += parseInt(value);
  };

  applyDamage = (e) => {
    e.preventDefault();
    if(e.altKey) {
      ReactGA.event({
        category: 'Damage Control',
        action: 'Clicked Apply Damage - Half'
      });
      this.props.store.screen.damageControl.applyDamage(true);
    }
    else {
      ReactGA.event({
        category: 'Damage Control',
        action: 'Clicked Apply Damage'
      });
      this.props.store.screen.damageControl.applyDamage();
    }
  };

  setHPChange = e => {
    this.props.store.screen.damageControl.HPChange = parseInt(e.target.value)
  };

  render(){
    const store = this.props.store.screen.damageControl;

    return (
      <DamageControlContainer>
        <Typography variant="h5">
          Damage Control
        </Typography>
        <form onSubmit={this.applyDamage}>
          <DamageLabel variant="body1">
            Damage Taken {store.HPChange < 0 && `(Healing)`}
          </DamageLabel>

          <DamageFormControl
            aria-describedby="weight-helper-text"
              style={{
                backgroundColor: parseInt(store.HPChange) !== 0 ? (parseInt(store.HPChange) > 0 ? '#ffcbc9' : '#d4ecff') : '',
                borderColor: parseInt(store.HPChange) !== 0 ? (parseInt(store.HPChange) > 0 ? '#f90000' : '#0085f9') : '',
              }}
          >
            <Input
              value={store.HPChange}
              type="number"
              onChange={this.setHPChange}
            />
          </DamageFormControl>
        </form>

        <div>
          <div>
            <Typography variant='subtitle1'><i className='ra ra-bleeding-hearts' /> DEAL</Typography>
            {this.DEAL_CHANGE_BUTTONS.map((button, index) =>
              (
                <DealButton key={`hpdelta-heal-${index}`} variant="outlined" size="small" color="secondary" onClick={this.updateHPChage(button)}>
                  {button}
                </DealButton>
              )
            )}
          </div>

          <div>
            <Typography variant='subtitle1'><i className='ra ra-heart-bottle' /> HEAL</Typography>
            {this.HEAL_CHANGE_BUTTONS.map((button, index) =>
              (
                <HealButton key={`hpdelta-heal-${index}`} variant="outlined" size="small" color="secondary" onClick={this.updateHPChage(button)}>
                  {button}
                </HealButton>
              )
            )}
          </div>

          <ApplyContainer>
            <ApplyButton variant='contained' color='secondary' onClick={this.applyDamage}>
              APPLY TO SELECTED
            </ApplyButton>
          </ApplyContainer>
        </div>
      </DamageControlContainer>
    )
  }
}

export default DamageControl;
