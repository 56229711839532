import React, {Component} from "react";
import {
  Button,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Link,
  TextField,
} from "@material-ui/core";
import {api} from "../../../api/agent";
import {inject, observer} from "mobx-react";
import {ErrorsList} from "../forms/styled";
import {StyledDialogActions} from "../../styled/dialogs";


@inject('store') @observer
class SignInDialog extends Component {

  state = {
    email: '',
    password: '',
    errors: []
  };

  signIn = (event) => {
    event.preventDefault();
    api.Auth.signIn(this.state)
      .then(() => {
        this.props.store.signedIn = true;
        this.props.store.signInDialogOpen = false;
      })
      .catch(err => {
        this.setState({errors: err});
      })
  };

  goToSignUp = () => {
    this.props.store.signInDialogOpen = false;
    this.props.store.signUpDialogOpen = true;
  }

  handleClose = () => {
    this.props.store.signInDialogOpen = false;
  }


  render() {
    const { classes, ...other } = this.props;

    return (
      <Dialog
        open={this.props.store.signInDialogOpen}
        onClose={this.handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        {...other}
      >
        <DialogTitle id="alert-dialog-title">Sign In / Sign Up</DialogTitle>
        <form onSubmit={this.signIn}>
          <DialogContent>
            {/* TODO: Remove this extra signup button with the next release */}
            <div style={{textAlign: "center",  padding: "20px 0", margin: "0 0 10px 0" }}>
              <strong>No account yet?</strong> <br/><br/>

              <Button variant="contained" color="secondary"
                      size="large"
                      style={{marginBottom: 40}}
                      onClick={this.goToSignUp}>SIGN UP FOR FREE</Button>

              <Divider/>
            </div>


            <DialogContentText id="alert-dialog-description">
              To sign into Toady, please enter your email address and password below.
            </DialogContentText>
            <TextField
              autoFocus
              margin="dense"
              id="email-address"
              label="Email Address"
              type="email"
              fullWidth
              error={'email' in this.state.errors}
              helperText={'email' in this.state.errors ? this.state.errors.email.join(" ") : null}
              onChange={(event) => this.setState({email: event.target.value})}
            />
            <TextField
              margin="dense"
              id="password"
              label="Password"
              type="password"
              fullWidth
              error={'password' in this.state.errors}
              helperText={'password' in this.state.errors ? this.state.errors.password.join(" ") : null}
              onChange={(event) => this.setState({password: event.target.value})}
            />
            {'non_field_errors' in this.state.errors && (
              <DialogContentText id="alert-dialog-description">
                <ErrorsList>
                  {this.state.errors.non_field_errors.map((error, index) => (
                    <li key={`signin-form-error-${index}`}>
                      {error}
                    </li>
                  ))}
                </ErrorsList>
              </DialogContentText>
            )}
          </DialogContent>
          <StyledDialogActions>
            <Button onClick={this.handleClose} color="primary">
              Cancel
            </Button>
            <Button onClick={this.signIn} color="primary" type={"submit"}>
              Sign In
            </Button>
          </StyledDialogActions>
              <br/>
              <Divider/>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              <br/>
              No account yet? You may <Link href="#" onClick={this.goToSignUp}>sign up as well</Link>.
            </DialogContentText>
          </DialogContent>
        </form>
      </Dialog>
    )
  }
}

export default SignInDialog;
