import React from "react";
import SchoolsOfMagic from "./SchoolsOfMagic";
import Conditions from "./Conditions";
import TravelPace from "./TravelPace";
import ExhaustionLevels from "./ExhaustionLevels";
import Cover from "./Cover";
import ProficiencyBonus from "./ProficiencyBonus";
import TypicalDCTable from "./TypicalDCTable";
import XPbyCR from "./XPbyCR";
import {MainGridContainer} from "../../../components/styled/layout";


import { Hidden, Grid } from '@material-ui/core';


export const Tables = (props) => {
  return <MainGridContainer container spacing={1}>
    <Hidden lgUp>
      <Grid item xs={12} sm={6} md={3} lg={2}>
        <SchoolsOfMagic />
      </Grid>
    </Hidden>

    <Grid item xs={12} sm={6} md={3} lg={2}>
      <Conditions />
    </Grid>

    <Grid item xs={12} sm={12} md={6} lg={8}>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <TravelPace />
        </Grid>
      </Grid>

      <Grid container spacing={1}>
        <Grid item xs={12} md={4} lg={6}>
          <ExhaustionLevels />
          <Cover />
          <ProficiencyBonus />
          <TypicalDCTable />
        </Grid>

        <Grid item xs={12} md={8} lg={6}>
          <XPbyCR />
        </Grid>
      </Grid>
    </Grid>

    <Hidden mdDown>
      <Grid item xs={12} sm={6} md={3} lg={2}>
        <SchoolsOfMagic />
      </Grid>
    </Hidden>
  </MainGridContainer>
};
