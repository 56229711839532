import React from 'react';
import PanelsList from "../../../components/common/PanelsList";


const Conditions = () => {
  return (
      <PanelsList
        storeName='conditions'
        title='Conditions'
        id='id_conditions'
      />
  )
};

export default Conditions;
