import {action, computed, observable, reaction} from 'mobx';
import axios from "axios";
import Cookies from "js-cookie";


const csrftoken = Cookies.get('csrftoken');


class ApiStore {
  @observable token = window.localStorage.getItem("token");

  constructor() {
    reaction(
      () => this.token,
      token => {
        if (token) {
          window.localStorage.setItem('token', token);
        } else {
          window.localStorage.removeItem('token');
        }
      }
    );
  }

  @action setToken(token) {
    this.token = token
  }

  @computed get signed() {
    return !!this.token
  }

  @action signOut() {
    this.setToken(null);
  }

  getAxiosInstance() {
    return axios.create({
      headers: {
        'X-CSRFToken': csrftoken,
        "Authorization": this.token ? `Token ${this.token}` : null
      }
    });
  }

}


export default new ApiStore();
