import {action, observable} from "mobx";
import {Spells} from "../../modules/5e/Spells/store";
import {NotesStore} from "../../modules/Notes/store";
import {Spotify} from "../../modules/Spotify/store";
import {GlobalSoundset, Soundset} from "../../modules/Syrinscape/store";
import {ConditionsStore} from "../../modules/5e/Tables/stores/Conditions";
import {SchoolsOfMagicStore} from "../../modules/5e/Tables/stores/SchoolsOfMagic";
import {
  DamageControlStore,
  NonPlayerCharacters,
  PlayerCharacters,
  TrackerStore
} from "../../modules/5e/CombatTracker/stores";



export class TabsStore {
  @observable currentTab = 0;

  @action.bound
  setTab(tab) {
    this.currentTab = tab;
  }

  @action.bound
  getCurrentTab() {
    return this.currentTab;
  }
}



export class DD5eStore {

  @observable setting_autoSort = true;
  @observable setting_npcsAutoInit = true;

  @observable spells = new Spells(this);

  @observable notes = new NotesStore(this);
  @observable current_note = null;


  @observable playlists = new Spotify(this);
  @observable soundset = new Soundset(this);
  @observable globalSoundset = new GlobalSoundset(this.soundset);

  @observable conditions = new ConditionsStore(this);
  @observable schoolsOfMagic = new SchoolsOfMagicStore(this);

  @observable canSwipe = true;
  @observable damageControl = new DamageControlStore(this);
  @observable tracker = new TrackerStore(this);

  @observable pcs = new PlayerCharacters(this);
  @observable npcs = new NonPlayerCharacters(this);

  dataStores = [
    "notes",
    "playlists",
    "tracker",
    "pcs",
    "npcs",
  ]

  constructor(root){
    this.root = root
  }


  @action.bound
  toggleNPCsAutoInit(){
    this.setting_npcsAutoInit = !this.setting_npcsAutoInit;
  }

  getNPCsAutoInit(){
    return this.setting_npcsAutoInit;
  }

  @action.bound
  toggleAutoSort(){
    this.setting_autoSort = !this.setting_autoSort;
    if(this.setting_autoSort){
      this.tracker.sortItems();
      this.tracker.saveData();
    }
  }

  getAutoSort(){
    return this.setting_autoSort;
  }

}
