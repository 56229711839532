import React from "react";
import {FullContent} from "./styled";
import {Helmet} from "react-helmet";

const AccountVerified = (props) => {
  return (
    <FullContent>
      <Helmet>
        <meta name="robots" content="noindex" />
      </Helmet>
      <h1>Account Verified</h1>

      <p>Your account has been activated. You can now sign in and synchronize your screen.</p>
    </FullContent>
  )
}

export default AccountVerified;
