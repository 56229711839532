import React from "react";
import NewsDialog from "../NewsDialog";
import signupGif from "../../../../media/images/signup.gif";
import {TextLink} from "../../../styled/links";
import {Typography} from "@material-ui/core";


const SignupAddedNewsDialog = () => {
  return <NewsDialog storagekey="signupAddedNewsDialog_v4_0_0" title="Accounts are here!">
    <Typography variant="body1">
    Now you can create a free account to sync Toady data across devices.<br/><br/>
    </Typography>

    <img src={signupGif} alt="Loading..."/><br/><br/>

    <Typography variant="body1">
      You can <TextLink to="/pages/about">read about Toady level up in our updates changelog</TextLink>.
    </Typography>
  </NewsDialog>
}

export default SignupAddedNewsDialog
