import React from "react";
import AddIcon from "@material-ui/icons/Add";
import {inject, observer} from "mobx-react";
import styled from "styled-components";
import DeleteConfirmationButton from "../../components/common/buttons/DeleteConfirmationButton";
import ReactGA from "react-ga";
import {MainGridContainer} from "../../components/styled/layout";

import { TextField, Typography, Button, Paper, Input, Grid } from '@material-ui/core';

const Note = styled(Paper)`
  padding: ${props => props.theme.spacing(1)}px;
`;

const NoteEntry = styled.div`
  width: 100%;
  display: flex;
  margin-bottom: ${props => props.theme.spacing(1)}px;
`;

const ActionButton = styled(Button)`
  width: 100%;
  margin-bottom: ${props => props.theme.spacing(1)}px;
`;

const AddButton = styled(Button)`
  flex-grow: 1;
  & span{
    justify-content: start;
  }
`;

const Header = styled(Typography)`
  margin-bottom: ${props => props.theme.spacing(1)}px;
`;

const CustomAddIcon = styled(AddIcon)`
  font-size: 20px;
  margin-right: ${props => props.theme.spacing(1)}px;
`;

const NoteTitle = styled(Input)`
  width: 100%;
  margin-bottom: ${props => props.theme.spacing(1)}px;
`;


@inject('store') @observer
class Notes extends React.Component {

  componentDidMount(){
    this.props.store.screen.notes.fetchData();
  }

  setNote = (note) => {
    this.props.store.screen.current_note = note;
  };

  setNoteContent = (event) => {
    this.props.store.screen.current_note.content = event.target.value;
    this.props.store.screen.notes.saveData();
  };

  setNoteTitle = (event) => {
    this.props.store.screen.current_note.title = event.target.value;
    this.props.store.screen.notes.saveData();
  };

  getNoteDate = () => {
    const date = new Date(this.props.store.screen.current_note.id);

    const year = date.getFullYear();
    const month = "0" + date.getMonth();
    const day = "0" + date.getDate();
    const hours = date.getHours();
    const minutes = "0" + date.getMinutes();

    return year + '-' + month.substr(-2) + '-' + day.substr(-2) + '@' + hours + ':' + minutes.substr(-2);
  };

  addNote() {
    ReactGA.event({
      category: 'Notes',
      action: 'Created new note'
    });
    const note = this.props.store.screen.notes.createNote();
    this.props.store.screen.current_note = note;
  }

  remove = (note) => {
    if(this.props.store.screen.current_note !== null && this.props.store.screen.current_note.id === note.id){
      this.props.store.screen.current_note = null;
    }
    this.props.store.screen.notes.removeItem(note);
  };

  render() {
    return (
      <MainGridContainer container spacing={1}>
        <Grid item xs={12}>
          <Header variant="h4">
            Your notes
          </Header>
          <Grid container spacing={1}>
            <Grid item xs={12} sm={3}>
              <ActionButton variant={'contained'} color={'secondary'} onClick={() => this.addNote()}>
                <CustomAddIcon/>
                Add new note
              </ActionButton>
              {this.props.store.screen.notes.items.slice(0).reverse().map(
                (note,index) => (
                  <NoteEntry key={`note-entry-${index}`}>
                    <AddButton
                      key={`note-item-${index}`}
                      onClick={() => this.setNote(note)}>
                      {note.title}
                    </AddButton>
                    <DeleteConfirmationButton onConfirm={() => this.remove(note)} />
                  </NoteEntry>
                )
              )}
            </Grid>
            <Grid item xs={12} sm={9}>
              {this.props.store.screen.current_note && (
                <Note>
                  <Typography variant={'caption'}>
                    Created: {this.getNoteDate()}
                  </Typography>
                  <NoteTitle
                    id={`note-${this.props.store.screen.current_note.id}`}
                    value={this.props.store.screen.current_note.title}
                    placeholder={'Note title'}
                    onChange={this.setNoteTitle}
                  />
                  <TextField
                    multiline
                    fullWidth
                    rows="20"
                    onChange={this.setNoteContent}
                    value={this.props.store.screen.current_note.content}
                  />
                </Note>
              )}
            </Grid>
          </Grid>
        </Grid>
      </MainGridContainer>
    );
  }
}

export default Notes;
