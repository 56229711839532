import React from 'react';
import moment from 'moment-timezone';
import {inject, observer} from "mobx-react";
import {Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Divider} from "@material-ui/core";
import { SyncManager } from ".";


@inject('store') @observer
class SyncConflictDialog extends React.Component<any, any> {

  manager: SyncManager

  constructor(props) {
    super(props);
    this.manager = new SyncManager(this.props.store)
  }

  keepLocal = () => {
    this.manager.resolveConflictPushData()
  }

  keepRemote = () => {
    this.manager.resolveConflictPullData()
  }

  handleClose = () => {
    this.manager.cleanupConflict()
  }

  render() {
    const serverLastSync = moment(this.props.store.serverLastSync)
    const clientLastSync = moment(this.props.store.clientLastSync)

    return (
      <Dialog
        open={this.props.store.conflictDialogOpen}
        onClose={this.handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Library Data Conflict</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              My liege, <br/>
              I have noticed that your library on the remote service might have been changed with another device.
              Would you like to keep local data from this device or pull remote information? Either way
              the other one will be removed.
              <br/><br/>

              <strong>Last changes on remote server:</strong> {serverLastSync.format('dddd, D MMMM YYYY, H:mm:ss')}<br/>
              <strong>Last changes on this device:</strong> {clientLastSync.format('dddd, D MMMM YYYY, H:mm:ss')}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button color="primary" onClick={this.keepLocal}>
              Keep local
            </Button>
            <Button color="primary" onClick={this.keepRemote}>
              Keep remote
            </Button>
          </DialogActions>
              <br/>
              <Divider/>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Toady stores your data on both: your local device and on our remote servers.
              All changes you make are saved only locally. Sync uploads the data, so you can
              download them on another device.
              If you upload the data on one device it may cause conflicts on the other.
            </DialogContentText>
          </DialogContent>
      </Dialog>
    );
  }
}

export default SyncConflictDialog;
