import {observable, action} from "mobx";
import {TrackerCreatureCopy} from "./Tracker";


interface CopyComplex {
  copy: TrackerCreatureCopy,
  id: string
}


export class DamageControlStore {
  @observable HPChange: number = 0;
  @observable selected: CopyComplex[] = [];

  @action.bound
  isCopySelected(creatureCopy) {
    return this.selected.find(selected => selected.copy.id === creatureCopy.id && selected.id === creatureCopy.root.id);
  }

  @action.bound
  toggleSelect(creatureCopy){
    if(this.isCopySelected(creatureCopy)){
      this.selected = this.selected.filter(
        // fixme: replace root.id with some uique id for the copy in general
        selected => !(selected.copy.id === creatureCopy.id && selected.id === creatureCopy.root.id)
      );
    }
    else {
      // fixme: push only copy but with unique id
      this.selected.push({copy: creatureCopy, id: creatureCopy.root.id});
    }

    creatureCopy.toggleSelect();
  }

  @action.bound
  addSelected(creatureCopy){
    if(!this.isCopySelected(creatureCopy)) {
      this.selected.push({copy: creatureCopy, id: creatureCopy.root.id});
    }
  }

  @action.bound
  applyDamage(half=false){
    const damage = half ? Math.floor(this.HPChange / 2) : this.HPChange;
    this.selected.map(creature => creature.copy.applyDamage(damage))
  }
}
