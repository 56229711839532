import React from 'react';
import ScreenTable from "../../../components/common/ScreenTable";

const coverData = [['1/2', '+2'],['3/4', '+5']];

const Cover = (props) => {
  return <ScreenTable
    title="Cover"
    body={coverData}
    note="In Total cover the target can't be targeted directly, but might still be affected by AoE spells etc."
  />
};

export default Cover;
