import {PanelsListItem, PanelsListStore} from "./PanelsListStore";

class ConditionItem extends PanelsListItem {
  constructor(index, name, desc){
    super(index, name, desc);
    this.desc = this.desc.map(item => item.replace('• ', ''))
  }
}

export class ConditionsStore extends PanelsListStore {
  ItemClass = ConditionItem;
  url = '/v1/screens/5e/conditions/';
}
