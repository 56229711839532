import React from 'react';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import {inject, observer} from "mobx-react";
import styled from "styled-components";
import CollapsibleBlock from "../common/CollapsibleBlock";


import { ExpansionPanel, ExpansionPanelDetails, ExpansionPanelSummary, Typography } from '@material-ui/core';


const SpacyExpansionPanelDetails = styled(ExpansionPanelDetails)`
  padding: 0 ${props => props.theme.spacing(2)}px ${props => props.theme.spacing(1)}px;
`;


const StyledList = styled.ul`
  padding-left: 10px;
  margin-top: 0;
`;

const StyledListItem = styled.li`
  margin-bottom: 10px;
  font-size: 0.85em;
`;

const StyledTypography = styled(Typography)`
  font-size: 0.85em;
`;


@inject('store') @observer
class ItemPanel extends React.Component {

  get_store = () => {
    return this.props.store.screen[this.props.storeName];
  };

  set_panel = () => {
    const { number } = this.props;
    this.get_store().setPanel(this.get_store().isCurrent(number) ? null : number);
  };

  is_expanded(){
    const { number } = this.props;
    return number === this.get_store().currentPanel
  };

  render() {
    const { title } = this.props;

    return (
      <ExpansionPanel expanded={ this.is_expanded() } onChange={ this.set_panel }>
        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>{ title }</Typography>
          <Typography>
          </Typography>
        </ExpansionPanelSummary>
        <SpacyExpansionPanelDetails>
          {this.props.children}
        </SpacyExpansionPanelDetails>
      </ExpansionPanel>
    )
  }
}

const StyledItemPanel = styled(ItemPanel)`
  margin: 0;
  padding: 0;
`;

@inject('store') @observer
class PanelsList extends React.Component {

  componentDidMount() {
    this.props.store.screen[this.props.storeName].fetchData();
  }

  render() {
    const {title, id, storeName} = this.props;
    return (<CollapsibleBlock title={title} id={id}>
    {this.props.store.screen[this.props.storeName].items.map((item, index) => {
      return (
        <StyledItemPanel key={`${id}-${index}`} number={ item.index } title={ item.name } storeName={storeName}>
          <StyledList>
            {Array.isArray(item.desc) ?
              item.desc.map((desc, index) => {
              return (
                <StyledListItem key={`${id}-effect-${index}`}>{ desc }</StyledListItem>
              )
              }) : (<StyledTypography variant='body1'>{item.desc}</StyledTypography>)
            }
          </StyledList>
        </StyledItemPanel>
      )
    })}
    </CollapsibleBlock>)
  }
}

export default PanelsList;
