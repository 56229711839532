import styled from "styled-components";
import { FormControl } from '@material-ui/core';


const DamageFormControl = styled(FormControl)`
  width: ${props => props.theme.spacing(10)}px;
  border: 2px solid ${props => props.theme.palette.damageControl.deal.main};
  border-radius: 3px;
  margin: ${props => props.theme.spacing(1)}px 0 ${props => props.theme.spacing(2)}px;
  & input {
    text-align: center;
    padding: 8px 0 8px 8px;
    border: none;
    outline: 0;
  }
`;

export default DamageFormControl;
