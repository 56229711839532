import {observable} from "mobx";
import {loadState, setState} from "../../utils/localStorage";
import {api} from "../../api/agent";


export class Sound {
  @observable url;
  @observable label;

  constructor(root, {url, label, size=2, icon=null}) {
    this.root = root;
    this.url = url;
    this.label = label;
    this.icon = icon;
    this.size = size;
  }

  play() {
    api.get('/v1/syrinscape/', {
        url: this.url,
        token: this.root.apiToken
    });
  }

  getData() {
    return {
      url: this.url,
      label: this.label
    }
  }
}


export class Soundset {
  dbKey = 'soundset';

  @observable apiToken;
  @observable items;

  constructor(root) {
    this.root = root;
    this.items = [];
    this.load();
  }

  add(data) {
    this.addSound(new Sound(this, data))
  }

  addSound(sound) {
    this.items.push(sound)
  }

  getData() {
    return {
      apiToken: this.apiToken,
      items: this.items.map(item => item.getData())
    }
  }

  saveData() {
    setState(this.dbKey, this.getData());
  }

  load() {
    const loadedData = loadState(this.dbKey);
    if (loadedData) {
      this.apiToken = loadedData.apiToken;
      loadedData.items.map(item => this.add(item));
    }
  }

}


export class GlobalSoundset extends Soundset{
  @observable items;

  load() {
    api.get('/v1/syrinscape/', {
      url: 'https://www.syrinscape.com/online/frontend-api/global-elements/',
      token: this.root.apiToken
    }).then(data => {
        if(data) {
          data.map(item => this.addSound(new Sound(this.root, {
            url: `https://www.syrinscape.com/online/frontend-api/elements/${item.pk}/play/`,
            icon: item.icon,
            size: 1,
            label: item.name
          })));
        }
    })
  }
}

