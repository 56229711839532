import {pick} from "lodash";
import {action, observable} from "mobx";
import {loadState, setState} from "../../../../utils/localStorage";
import {Character} from ".";
import {removeFromArray} from "../../../../utils/utils";
import {characterDataValidator} from "./validators";
import {number, optional, string} from "@typeofweb/schema";

class CharacterList {
  @observable root
  @observable items: Character[]
  @observable currentId: number = 0

  key: string = ''
  id_prefix: string = ''

  dumpKeys: string[] = ["name", "init", "init_mod", "hp", "hpMax", "hpDelta", "ac", "multiple", "id_prefix"]

  constructor(root){
    this.root = root;
    this.items = observable([]);
  }

  @action.bound
  load(){
    const data = loadState(this.key)
    return data ? this.loadData(data.items) : false
  }

  dumpData() {
    return this.items.map(item => pick(item, this.dumpKeys))
  }

  /**
   * Temporary method for unifying data coming from the store
   * until it is properly stored as proper data types.
   *
   * Remove it in the future when data is unified finally.
   *
   * @param item
   */
  tmpMapItem(item){
    return {
      name: item.name,
      init: parseInt(item.init),
      init_mod: parseInt(item.init_mod),
      hp: parseInt(item.hp),
      hpMax: parseInt(item.hpMax),
      hpDelta: parseInt(item.hpDelta),
      ac: parseInt(item.ac),
      multiple: parseInt(item.multiple),
      id_prefix: item.id_prefix,
    }
  }

  loadData(data) {
    if(data.length){
      this.items = observable([])
      data.map((item) => {
        const character = this.createCharacter(characterDataValidator(this.tmpMapItem(item)))
        return this.appendCharacter(character)
      });
      return true
    }
    return false
  }

  saveData(){
    setState(this.key, {items: this.dumpData() })
  }

  @action.bound
  createCharacter(characterData) {
    const item = new Character(this, this.currentId);

    this.currentId++
    Object.assign(item, characterData)

    if(!characterData.id_prefix)
      item.id_prefix = this.id_prefix;

    return item;
  }

  @action.bound
  appendCharacter(character, save=true){
    this.items.push(character);
    if (save) {
      this.saveData();
    }
  }

  @action.bound
  prependCharacter(character, save=true){
    this.items = observable([character, ...this.items]);
    if (save) {
      this.saveData();
    }
  }

  @action.bound
  addCharacter(character){
    this.items = observable([...this.items, character]);
    this.saveData();
    this.load();
    return this.getByID(character.id);
  }

  getByID(id){
    return this.items.find(obj => obj.id = id)
  };

  @action.bound
  removeItem(id) {
    this.items = observable(removeFromArray(this.items, "id", id));
    this.saveData();
  }

  @action.bound
  setSelectAll(selected=false){
    this.items.map(item => item.selected = selected);
  }
}


export class PlayerCharacters extends CharacterList {
  key = 'PlayerCharacters';
  id_prefix = 'PlayerCharacters-';

  constructor(root){
    super(root);
    this.load();
  }

  @action.bound
  toggleSelectedCharacter(id){
    this.items.forEach(item => {
      if(item.id === id){
        item.toggleSelected();
      }
    })
  }

  @action.bound
  addSelectedToTracker(){
    this.items.forEach(item => {
      if(item.selected){
        const character = this.root.tracker.createCharacter(item);
        character.type = 'PC';
        this.root.tracker.appendItem(character, false);
        item.toggleSelected();
      }
    });
    this.root.tracker.saveData();
  }
}

export class NonPlayerCharacters extends CharacterList {
  key = 'NonPlayerCharacters';
  id_prefix = 'NonPlayerCharacters-';

  constructor(root){
    super(root);
    this.load();
  }

  @action.bound
  toggleSelectedCharacter(id){
    this.items.forEach(item => {
      if(item.id === id){
        item.toggleSelected();
      }
    })
  }

  @action.bound
  addSelectedToTracker(){
    this.items.forEach(item => {
      if(item.selected){
        if(this.root.getNPCsAutoInit()) {
          item.rollInit();
        }
        const character = this.root.tracker.createCharacter(item);
        character.type = 'NPC';
        this.root.tracker.appendItem(character, false);
        item.toggleSelected();
      }
    });
    this.root.tracker.saveData();
  }
}
