import styled from "styled-components";
import React from "react";
import { Typography } from '@material-ui/core';

export const Note = styled(Typography)`
  padding: ${props => props.theme.spacing(1)}px;
  font-size: 0.7rem;
  color: ${props => props.theme.palette.grey['600']}; 
`;

export const Header6 = (props) => {
  return <Typography variant="h6">{props.children}</Typography>
};
