import React from 'react';
import ScreenTable from "../../../components/common/ScreenTable";


const xpData = [
    ['0', '0 or 10', '14', 11500],
    ['1/8', 25, '15', 13000],
    ['1/4', 50, '16', 15000],
    ['1/2', 100, '17', 18000],
    ['1', 200, '18', 20000],
    ['2', 450, '19', 22000],
    ['3', 700, '20', 25000],
    ['4', 1100, '21', 33000],
    ['5', 1800, '22', 41000],
    ['6', 2300, '23', 50000],
    ['7', 2900, '24', 62000],
    ['8', 3900, '25', 75000],
    ['9', 5000, '26', 90000],
    ['10', 5900, '27', 105000],
    ['11', 7200, '28', 120000],
    ['12', 8400, '29', 135000],
    ['13', 10000, '30', 155000],
];

const XPbyCR = (props) => {
  return <ScreenTable
    title="Experience Points by CR"
    body={xpData}
  />
};

export default XPbyCR;
