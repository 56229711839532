import React from 'react';
import ScreenTable from "../../../components/common/ScreenTable";


const exhaustionData = [
  ['1', 'Disadvantage on ability checks'],
  ['2', 'Speed halved'],
  ['3', 'Disadvantage on attack rolls and saving throws'],
  ['4', 'Hit point maximum halved'],
  ['5', 'Speed reduced to 0'],
  ['6', 'Death'],
];


const ExhaustionEffects = (props) => {
  return <ScreenTable
    title="Exhaustion Levels"
    body={exhaustionData}
  />
};

export default ExhaustionEffects;
