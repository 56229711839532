import React from 'react';
import ScreenTable from "../../../components/common/ScreenTable";


const proficiencyBonusData = [
  ['1-4', '+2'],
  ['5-8', '+3'],
  ['9-12', '+4'],
  ['13-16', '+5'],
  ['17-20', '+6']
];


const ProficiencyBonus = (props) => {
  return <ScreenTable
    title="Proficiency Bonus"
    body={proficiencyBonusData}
  />
};


export default ProficiencyBonus;
