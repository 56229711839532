import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import * as Sentry from '@sentry/browser';
import App from './App';
import * as serviceWorker from './serviceWorker';
// import ReactGA from 'react-ga';
import * as ReactGA from 'react-ga'
import packageJSON from '../package.json';
import {DEVEL} from "./config";

if(DEVEL) {
  ReactGA.initialize('UA-11059189-20', {debug: true});
}
else {
  ReactGA.initialize('UA-11059189-20', {debug: false});
}
ReactGA.pageview(window.location.pathname + window.location.search);

if(!DEVEL) {
  Sentry.init({
    dsn: "https://f4a8850e2051485facdb696296ae9cf3@sentry.io/1486736",
    release: `toady-frontend@${packageJSON.version}`
  });
}

ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();
